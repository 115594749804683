import { Routes, Route, Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Dropdown from "react-bootstrap/Dropdown";
import Accordion from "react-bootstrap/Accordion";
import Spinner from "react-bootstrap/Spinner";
import Home from "../components/home";
//Auth
import Dashboard from "../components/dashboard";
//import AuthUser from "../components/AuthUser";

//EmployeeInfo
import Employee from "../components/EmployeeInfo/employee";
import ManageEemployee from "../components/EmployeeInfo/manageEemployee";
import EditEmployee from "../components/EmployeeInfo/Edit";

//EmployeeLog
import EmployeeLog from "../components/EmployeeLog/employeelog";
import PermanentLog from "../components/PermanentLog/Permanentlog";
import EditPermanentLog from "../components/PermanentLog/Edit";

import SalaryDeduction from "../components/SalaryDeduction/addsalary";
import SalaryDetails from "../components/SalaryDetails/salarydetails";
import PaySalary from "../components/paySlip/paySalary";
import SalarayArchive from "../components/SalarayArchive/salarayarchive";

//Designation
import Designation from "../components/Designation/Designation";
import ManageDesignation from "../components/Designation/manageDesignation";
import EditDesignation from "../components/Designation/Edit";
//Branch
import Branch from "../components/Branch/Branch";
import ManageBranch from "../components/Branch/ManageBranch";
import EditBranch from "../components/Branch/Edit";
//Department
import Department from "../components/Department/Department";
import ManageDepartment from "../components/Department/manageDepartment";
import EditDepartment from "../components/Department/Edit";

// Fixed
import ManageFixedEarning from "../components/Fixed/ManageFixedEarning";
import EditFixedEarning from "../components/Fixed/Edit";

// CM
import CmEarning from "../components/CM/CmEarning";
import ManageCmEarning from "../components/CM/ManageCmEarning";
import EditCm from "../components/CM/Edit";
import CmSalaryReport from "../components/CM/CmSalaryReport";

// AddTardiness
import AddTardiness from "../components/AddTardiness/AddTardiness";
import ManageTardiness from "../components/AddTardiness/ManageTardiness";

// Location
import Location from "../components/Location/Location";
import ManageLocation from "../components/Location/ManageLocation";
import EditLocation from "../components/Location/Edit";

//Entity
import AddEntity from "../components/Entity/AddEntity";
import ManageEntity from "../components/Entity/ManageEntity";
import Edit from "../components/Entity/Edit";

// Settings
import AddCompany from "../components/Settings/AddCompany";
import EditCompany from "../components/Settings/EditCompany";
import ManageCompany from "../components/Settings/ManageCompany";
import PaySlip from "../components/Settings/PaySlip";
import Generated from "../components/SalaryReport/Generated";

import SalarySetting from "../components/Settings/SalarySetting";
import PaySlipSettings from "../components/Settings/PaySlipSettings";
import ManagePaySlipSettings from "../components/Settings/ManagePaySlipSettings";

import HrPolicy from "../components/Settings/HrPolicy";

//Salary Report
import PayableSalary from "../components/SalaryReport/PayableSalary";
import CmSalary from "../components/SalaryReport/CmSalary";
import ReportArchive from "../components/SalaryReport/ReportArchive";
import AuthUser from "../components/AuthUser";

//File Management
import FileManagement from "../components/FileManagement/EmployeeFileManagement";
import AddFile from "../components/FileManagement/AddFile";

function Auth() {
  const [manageCompany, setmanageCompany] = useState([]);

  useEffect(() => {
    fetchCompany();
  }, []);

  const fetchCompany = async () => {
    await axios
      .get(process.env.REACT_APP_API + "/company/user/1")
      .then(({ data }) => {
        setmanageCompany(data);
      });
  };

  // const { http } = AuthUser();
  // const [userdetail, setUserdetail] = useState("");
  const { token, logout } = AuthUser();
  const logoutUser = () => {
      if (token != undefined) {
          logout();
      }
  };

  // useEffect(() => {
  //     fetchUserDetail();
  // }, []);

  // const fetchUserDetail = () => {
  //     http.post("/me").then((res) => {
  //         setUserdetail(res.data);
  //     });
  // };

  // function renderElement() {
  //     if (userdetail) {
  //         return (
  //             <div>
  //                 <p>{userdetail.name}</p>
  //             </div>
  //         );
  //     } else {
  //         return (
  //             <div animation="border" role="status">
  //                 {/* <span className="visually-hidden">Loading...</span> */}
  //             </div>
  //         );
  //     }
  // }

  return (
    <div id="wrapper">
      <ul
        className="navbar-nav bg-gradient-secondary sidebar sidebar-dark accordion"
        id="accordionSidebar"
      >
        <div className="sidebar-brand d-flex align-items-center">
          <div className="sidebar-brand-text d-flex justify-content-between">
            <div></div>
            <div></div>
            <h2 className="dashboard-text">HRM </h2>
            <img
              className="img-profile rounded-circle logoutsite"
              src="img/undraw_profile.svg"
              alt=""
            />
            <h2 className="dashboard-text">
              <i class="bi bi-box-arrow-in-right"></i>
            </h2>

            {/* <button className="ms-2" type="button">
              <i class="bi bi-box-arrow-in-right"></i>
            </button> */}
            {/* <button className="btn btn-light ms-2" type="button">
              <i class="bi bi-box-arrow-in-right"></i>
            </button> */}
          </div>
        </div>
        <hr className="sidebar-divider my-0" />

        <Accordion>
          <Accordion.Item eventKey="0" className="navdropdownmenu">
            <Accordion.Header>
              <i class="fas fa-user-alt"></i> Employee
            </Accordion.Header>
            <Accordion.Body>
              <li class="nav-item">
                <Link to={"/employee"} className="nav-link collapsed pt-0 pb-2">
                  <i class="fas fa-user-alt"></i>
                  <span>Add New</span>
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  to={"/manageeemployee"}
                  className="nav-link collapsed pt-0 pb-2"
                >
                  <i class="fas fa-users"></i>
                  <span>Manage Info</span>
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  to={"/managefixedearning"}
                  className="nav-link collapsed pt-0 pb-2"
                >
                  <i class="fas fa-coins"></i>
                  <span>Manage E/D</span>
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  to={"/filemanagement"}
                  className="nav-link collapsed pt-0 pb-2"
                >
                  <i class="fas fa-upload"></i>
                  <span>File Manage</span>
                </Link>
              </li>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1" className="navdropdownmenu">
            <Accordion.Header>
              <i class="fas fa-align-justify"></i>CM Salary
            </Accordion.Header>
            <Accordion.Body>
              <li class="nav-item">
                <Link
                  to={"/cmearning"}
                  className="nav-link collapsed pt-0 pb-2"
                >
                  <i class="fa fa-arrow-circle-right"></i>
                  <span>Add CM Salary</span>
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  to={"/managecmearning"}
                  className="nav-link collapsed pt-0 pb-2"
                >
                  <i class="fa fa-arrow-circle-right"></i>
                  <span>Manage CM Salary </span>
                </Link>
              </li>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="2" className="navdropdownmenu">
            <Accordion.Header>
              <i class="far fa-clock"></i>Tardiness Consideration
            </Accordion.Header>
            <Accordion.Body>
              <li class="nav-item">
                <Link
                  to={"/addtardiness"}
                  className="nav-link collapsed pt-0 pb-2"
                >
                  <i class="fa fa-arrow-circle-right"></i>
                  <span>Add Consideration </span>
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  to={"/managetardiness"}
                  className="nav-link collapsed pt-0 pb-2"
                >
                  <i class="fa fa-arrow-circle-right"></i>
                  <span>Manage Consideration </span>
                </Link>
              </li>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="3" className="navdropdownmenu">
            <Accordion.Header>
              <i class="fas fa-random"></i>
              Entity
            </Accordion.Header>
            <Accordion.Body>
              <li class="nav-item">
                <Link
                  to={"/addentity"}
                  className="nav-link collapsed pt-0 pb-2"
                >
                  <i class="fa fa-arrow-circle-right"></i>
                  <span>Add Entity </span>
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  to={"/manageentity"}
                  className="nav-link collapsed pt-0 pb-2"
                >
                  <i class="fa fa-arrow-circle-right"></i>
                  <span>Manage Entity </span>
                </Link>
              </li>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="4" className="navdropdownmenu">
            <Accordion.Header>
              <i class="fas fa-route"></i>Location
            </Accordion.Header>
            <Accordion.Body>
              <li class="nav-item">
                <Link to={"/location"} className="nav-link collapsed pt-0 pb-2">
                  <i class="fa fa-arrow-circle-right"></i>
                  <span>Add Location</span>
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  to={"/managelocation"}
                  className="nav-link collapsed pt-0 pb-2"
                >
                  <i class="fa fa-arrow-circle-right"></i>
                  <span>Manage Location </span>
                </Link>
              </li>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="5" className="navdropdownmenu">
            <Accordion.Header>
              <i class="fas fa-user-alt"></i> Designation
            </Accordion.Header>
            <Accordion.Body>
              <li class="nav-item">
                <Link
                  to={"/designation"}
                  className="nav-link collapsed pt-0 pb-2"
                >
                  <i class="fas fa-user-alt"></i>
                  <span>Add Designation</span>
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  to={"/managedesignation"}
                  className="nav-link collapsed pt-0 pb-2"
                >
                  <i class="fas fa-users"></i>
                  <span>Manage Designation</span>
                </Link>
              </li>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="6" className="navdropdownmenu">
            <Accordion.Header>
              <i class="fa fa-envelope-open"></i> Department
            </Accordion.Header>
            <Accordion.Body>
              <li class="nav-item">
                <Link
                  to={"/department"}
                  className="nav-link collapsed pt-0 pb-2"
                >
                  <i class="fa fa-arrow-circle-right"></i>
                  <span>Add Department</span>
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  to={"/managedepartment"}
                  className="nav-link collapsed pt-0 pb-2"
                >
                  <i class="fa fa-arrow-circle-right"></i>
                  <span>Manage Department</span>
                </Link>
              </li>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="7" className="navdropdownmenu">
            <Accordion.Header>
              <i class="fas fa-code-branch"></i> Branch
            </Accordion.Header>
            <Accordion.Body>
              <li class="nav-item">
                <Link to={"/branch"} className="nav-link collapsed pt-0 pb-2">
                  <i class="fas fa-code-branch"></i>
                  <span>Add Branch</span>
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  to={"/managebranch"}
                  className="nav-link collapsed pt-0 pb-2"
                >
                  <i class="fas fa-bezier-curve"></i>
                  <span>Manage Branch</span>
                </Link>
              </li>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="8" className="navdropdownmenu">
            <Accordion.Header>
              <i class="far fa-clock"></i> Attendance Report
            </Accordion.Header>
            <Accordion.Body>
              <li class="nav-item">
                <Link
                  to={"/permanentlog"}
                  className="nav-link collapsed pt-0 pb-2"
                >
                  <i class="far fa-clock"></i>
                  <span>Manage Attendance</span>
                </Link>
              </li>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="9" className="navdropdownmenu">
            <Accordion.Header>
              <i class="fas fa-chart-bar"></i>Salary Report
            </Accordion.Header>
            <Accordion.Body>
              <li class="nav-item">
                <Link
                  to={"/reportarchive"}
                  className="nav-link collapsed pt-0 pb-2"
                >
                  <i class="far fa-clock"></i>
                  <span>Report Archive</span>
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  to={"/payablesalary"}
                  className="nav-link collapsed pt-0 pb-2"
                >
                  <i class="far fa-clock"></i>
                  <span>Payable Salary Report</span>
                </Link>
              </li>
              <li class="nav-item">
                <Link to={"/cmsalary"} className="nav-link collapsed pt-0 pb-2">
                  <i class="far fa-clock"></i>
                  <span>CM Salary</span>
                </Link>
              </li>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="10" className="navdropdownmenu">
            <Accordion.Header>
              <i class="fa fa-cog"></i>Settings
            </Accordion.Header>
            <Accordion.Body>
              <li class="nav-item">
                <Link
                  to={"/managecompany"}
                  className="nav-link collapsed pt-0 pb-2"
                >
                  <i class="fa fa-arrow-circle-right"></i>
                  <span>Manage Company</span>
                </Link>
              </li>

              <li class="nav-item">
                <Link to={"/hrpolicy"} className="nav-link collapsed pt-0 pb-2">
                  <i class="fa fa-arrow-circle-right"></i>
                  <span>Salary Deduction HR Policy</span>
                </Link>
              </li>

              <li class="nav-item">
                <Link
                  to={"/payslipsettings"}
                  className="nav-link collapsed pt-0 pb-2"
                >
                  <i class="fa fa-arrow-circle-right"></i>
                  <span>PaySlip Settings</span>
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  to={"/managepayslipsettings"}
                  className="nav-link collapsed pt-0 pb-2"
                >
                  <i class="fa fa-arrow-circle-right"></i>
                  <span>Manage Signatory</span>
                </Link>
              </li>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <li class="nav-item">
          <Link to={"/salarydetails"} className="nav-link collapsed pt-0 pb-2">
            <i class="fas fa-code-branch"></i>
            <span>Leave Management </span>
          </Link>
        </li>

        <li class="nav-item">
          <Link to={"/salarydetails"} className="nav-link collapsed pt-0 pb-2">
            <i class="fas fa-code-branch"></i>
            <span>KRA and KPI </span>
          </Link>
        </li>

      </ul>

      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
            <button
              id="sidebarToggleTop"
              className="btn btn-link d-md-none rounded-circle mr-3"
            >
              <i className="fa fa-bars" />
            </button>
            <ul className="navbar-nav ml-auto">
              <li className="nav-item dropdown no-arrow">
                <Link className="nav-link dropdown-toggle p-1">
                  {manageCompany.length > 0 &&
                    manageCompany.map((row, key) => (
                      <img
                        src={row.logo}
                        alt="CloudFront image"
                        className="img-profile rounded-circle sizedataincrece"
                      />
                    ))}

                  <Dropdown>
                    <Dropdown.Toggle id="dropdown" variant="white">
                      {/* {renderElement()} */}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => logoutUser()}>
                        {" "}
                        <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                        Logout
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Link>
              </li>
            </ul>
          </nav>

          <div className="container">
            <Routes>
              <Route path="/home" element={<Home />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/employeelog" element={<EmployeeLog />} />
              <Route path="/permanentlog" element={<PermanentLog />} />
              <Route path="/filemanagement" element={<FileManagement />} />
              <Route path="/addfile" element={<AddFile />} />
              <Route
                path="/PermanentLog/edit/:ID"
                element={<EditPermanentLog />}
              />
              <Route path="/salarydeduction" element={<SalaryDeduction />} />
              <Route path="/salarydetails" element={<SalaryDetails />} />
              <Route path="/paysalary" element={<PaySalary />} />
              <Route path="/employee" element={<Employee />} />
              <Route path="/manageeemployee" element={<ManageEemployee />} />
              <Route
                path="/manageeemployee/edit/:Employee_ID"
                element={<EditEmployee />}
              />

              <Route path="/salarayarchive" element={<SalarayArchive />} />
              <Route path="/designation" element={<Designation />} />
              <Route
                path="/managedesignation"
                element={<ManageDesignation />}
              />
              <Route
                path="/designation/edit/:ID"
                element={<EditDesignation />}
              />
              <Route path="/branch" element={<Branch />} />
              <Route path="/managebranch" element={<ManageBranch />} />
              <Route path="/branch/edit/:ID" element={<EditBranch />} />
              <Route path="/department" element={<Department />} />
              <Route path="/managedepartment" element={<ManageDepartment />} />
              <Route path="/department/edit/:ID" element={<EditDepartment />} />
              <Route
                path="/managefixedearning"
                element={<ManageFixedEarning />}
              />
              <Route
                path="/managefixedearning/edit/:ID"
                element={<EditFixedEarning />}
              />
              <Route path="/cmearning" element={<CmEarning />} />
              <Route path="/managecmearning" element={<ManageCmEarning />} />
              <Route path="/cmsalaryreport" element={<CmSalaryReport />} />

              <Route path="/cm/edit/:ID" element={<EditCm />} />
              <Route path="/addtardiness" element={<AddTardiness />} />
              <Route path="/managetardiness" element={<ManageTardiness />} />
              <Route path="/addcompany" element={<AddCompany />} />
              <Route path="/addentity" element={<AddEntity />} />
              <Route path="/manageentity" element={<ManageEntity />} />
              <Route path="/entity/edit/:ID" element={<Edit />} />
              <Route
                path="/company/editcompany/:ID"
                element={<EditCompany />}
              />

              <Route path="/managecompany" element={<ManageCompany />} />
              <Route path="/salarysetting" element={<SalarySetting />} />
              <Route path="/hrpolicy" element={<HrPolicy />} />
              <Route path="/payslipsettings" element={<PaySlipSettings />} />
              <Route
                path="/managepayslipsettings"
                element={<ManagePaySlipSettings />}
              />

              <Route path="/payablesalary" element={<PayableSalary />} />
              <Route path="/cmsalary" element={<CmSalary />} />
              <Route path="/generated" element={<Generated />} />
              <Route path="/salaryreport/payslip/:ID" element={<PaySlip />} />

              <Route path="/reportarchive" element={<ReportArchive />} />

              <Route path="/Location" element={<Location />} />
              <Route path="/managelocation" element={<ManageLocation />} />
              <Route path="/location/edit/:ID" element={<EditLocation />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Auth;
