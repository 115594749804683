import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
export default function PaySlipSettings() {
  const navigate = useNavigate();
  const [validationError, setValidationError] = useState({});

  const createSignatory = async (e) => {
    e.preventDefault();

    var Name = document.getElementById("Name").value;
    var E_ID = document.getElementById("E_ID").value;
    var designation = document.getElementById("designation").value;

    const postData = {
      Name,
      E_ID,
      designation,
    };

    await axios
      .post(process.env.REACT_APP_API + "/signatory/APILimited", postData, {})
      .then(({ data }) => {
        Swal.fire({
          icon: "success",
          timer: 1500,
          text: data.message,
        });
        navigate("/payslipsettings");
      })
      .catch(({ response }) => {
        if (response.status === 422) {
          setValidationError(response.data.errors);
        } else {
          Swal.fire({
            text: response.data.message,
            icon: "error",
          });
        }
      });
  };

  return (
    <div>
      <div className="row">
        <div className="col-xl-12 col-md-12 card">
          <h3 className="card-title pt-3 ">PaySlip Settings</h3>
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Add Signatory</h4>
              <hr />
              <div className="form-wrapper">
                {Object.keys(validationError).length > 0 && (
                  <div className="row">
                    <div className="col-12">
                      <div className="alert alert-danger">
                        <ul className="mb-0">
                          {Object.entries(validationError).map(
                            ([key, value]) => (
                              <li key={key}>{value}</li>
                            )
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                )}

                <Form onSubmit={createSignatory}>
                  <Row>
                    <Col>
                      <Form.Group controlId="Name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" id="Name" required />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="designation">
                        <Form.Label>Designation</Form.Label>
                        <Form.Control type="text" id="designation" required />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="E_ID">
                        <Form.Label>Employee ID</Form.Label>
                        <Form.Control
                          type="text"
                          id="E_ID"
                          required
                          // disabled
                          placeholder="API2007003-xyz"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group as={Row} className="mb-3 mt-3">
                    <Col sm={{ span: 2, offset: 20 }}>
                      <Button variant="secondary" type="submit">
                        <i class="fa fa-save"></i> Save
                      </Button>
                    </Col>
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div>

          <div className="card mt-4">
            <div className="card-body">
              <h4 className="card-title">PaySlip Prefix</h4>
              <hr />
              <div className="form-wrapper">
                <Form>
                  <Row>
                    <Col>
                      <Form.Group controlId="Name">
                        <Form.Label>Starting Number</Form.Label>
                        <Form.Control
                          type="text"
                          id="startingnumber"
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="designation">
                        <Form.Check aria-label="option 1" />

                        <Form.Label>Print with Company Details</Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group as={Row} className="mb-3 mt-3">
                    <Col sm={{ span: 2, offset: 20 }}>
                      <Button variant="secondary" type="submit">
                        <i class="fa fa-save"></i> Save
                      </Button>
                    </Col>
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div>

          <div className="card mt-4">
            <div className="card-body">
              <h4 className="card-title">PaySlip Size</h4>
              <hr />
              <div className="form-wrapper">
                <Form>
                  <Row>
                    <Col>
                      <Form.Group controlId="Name">
                        <Form.Label>Copy</Form.Label>
                        <Form.Control
                          type="number"
                          id="startingnumber"
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group as={Col} controlId="Gender">
                        <Form.Label>Size</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          type="text"
                          id="status"
                          required
                        >
                          <option value="0">A1</option>
                          <option value="1">A2</option>
                          <option value="3">A3</option>
                          <option value="4">A4</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group as={Row} className="mb-3 mt-3">
                    <Col sm={{ span: 2, offset: 20 }}>
                      <Button variant="secondary" type="submit">
                        <i class="fa fa-save"></i> Save
                      </Button>
                    </Col>
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
