import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

export default function Branch() {
    const navigate = useNavigate();
    const [validationError, setValidationError] = useState({});

    const createBranch = async (e) => {
        e.preventDefault();

        var Country = document.getElementById("Country").value;
        var State = document.getElementById("State").value;
        var Division = document.getElementById("Division").value;
        var City = document.getElementById("City").value;
        var District = document.getElementById("District").value;
        var Area = document.getElementById("Area").value;
        var Address = document.getElementById("Address").value;

        const postData = {
            Country,
            State,
            Division,
            City,
            District,
            Area,
            Address,
        };

        await axios
            .post(
              process.env.REACT_APP_API + "/branch/APILimited",
                postData,
                {}
            )
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    timer: 1500,
                    text: data.message,
                });
                navigate("/branch");
            })
            .catch(({ response }) => {
                if (response.status === 422) {
                    setValidationError(response.data.errors);
                } else {
                    Swal.fire({
                        text: response.data.message,
                        icon: "error",
                    });
                }
            });
    };

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 col-sm-12 col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Create Branch</h4>
                            <hr />
                            <div className="form-wrapper">
                                {Object.keys(validationError).length > 0 && (
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="alert alert-danger">
                                                <ul className="mb-0">
                                                    {Object.entries(
                                                        validationError
                                                    ).map(([key, value]) => (
                                                        <li key={key}>
                                                            {value}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <Form onSubmit={createBranch}>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="Country">
                                                <Form.Label>Country</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="Country"
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="State">
                                                <Form.Label>State</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="State"
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Form.Group controlId="Division">
                                                <Form.Label>
                                                    Division
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="Division"
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="City">
                                                <Form.Label>City</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="City"
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="District">
                                                <Form.Label>
                                                    District
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="District"
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="State">
                                                <Form.Label>Area</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="Area"
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="Address">
                                                <Form.Label>Address</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="Address"
                                                    required
                                                    style={{ height: "100px" }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Form.Group as={Row} className="mb-3 mt-3">
                                        <Col sm={{ span: 2, offset: 20 }}>
                                            <Button
                                                variant="secondary"
                                                type="submit"
                                            >
                                               <i class="fa fa-save"></i>   Save
                                            </Button>
                                        </Col>
                                    </Form.Group>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
