import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

export default function AddFile() {
  const navigate = useNavigate();
  const [validationError, setValidationError] = useState({});

  const createLocation = async (e) => {
    e.preventDefault();

    var latitude = document.getElementById("latitude").value;
    var longitude = document.getElementById("longitude").value;
    var location_name = document.getElementById("location_name").value;

    const postData = {
      latitude,
      longitude,
      location_name,
    };

    await axios
      .post(process.env.REACT_APP_API + "/location/APILimited", postData, {})
      .then(({ data }) => {
        Swal.fire({
          icon: "success",
          timer: 1500,
          text: data.message,
        });
        navigate("/location");
      })
      .catch(({ response }) => {
        if (response.status === 422) {
          setValidationError(response.data.errors);
        } else {
          Swal.fire({
            text: response.data.message,
            icon: "error",
          });
        }
      });
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-sm-12 col-md-12">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Add File Management</h4>
              <hr />
              <div className="form-wrapper">
                {Object.keys(validationError).length > 0 && (
                  <div className="row">
                    <div className="col-12">
                      <div className="alert alert-danger">
                        <ul className="mb-0">
                          {Object.entries(validationError).map(
                            ([key, value]) => (
                              <li key={key}>{value}</li>
                            )
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                )}

                <Form onSubmit={createLocation}>
                  <Row>
                    <Col>
                      <Form.Group controlId="latitude">
                        <Form.Label>Caption</Form.Label>
                        <Form.Control type="text" id="latitude" required />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="longitude">
                        <Form.Label>Chose File</Form.Label>
                        <Form.Control type="file" required id="longitude" />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group controlId="location_name">
                        <Form.Label>Date</Form.Label>
                        <Form.Control type="date" required id="location_name" />
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group as={Col} controlId="Gender">
                        <Form.Label>Status *</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          type="text"
                          id="location_name"
                          required
                        >
                          <option value="0">Active</option>
                          <option value="1">Inactive</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group as={Row} className="mb-3 mt-3">
                    <Col sm={{ span: 2, offset: 20 }}>
                      <Button variant="secondary" type="submit">
                        <i class="fa fa-save"></i> Save
                      </Button>
                    </Col>
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
