import React from 'react';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
export default function PayableSalary() {
    return(
        <div>
             <p>Under Construction</p>
            
                </div>
        );
}