import React from "react";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
export default function salaryDetails() {
  return <div>
     <div>
                <p>Under Construction</p>
        </div>
  </div>;
}
