import Table from "react-bootstrap/Table";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import { PDFDownloadLink } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { ExportToExcel } from "./ExportToExcel";
import MyDocument from "./MyDocument";
import ReactPaginate from "react-paginate";

export default function PermanentLog() {
    const [number, setNumber] = useState(1); // No of pages
    const [PermanentlogPerPage] = useState(31);

    const [allLogs, setAllLogs] = useState([]);
    const [permanentlog, setPermanentlog] = useState([]);

    const [employerId, setEmployerId] = useState("");
    const [searchName, setSearchName] = useState("");
    const [searchDateRangeFrom, setSearchDateRangeFrom] = useState("");
    const [searchDateRangeTo, setSearchDateRangeTo] = useState("");

    const fileName = "PermanentLog"; // here enter filename for your excel file

    useEffect(() => {
        if (
            employerId ||
            searchName ||
            (searchDateRangeFrom && searchDateRangeTo)
        ) {
            let data = allLogs.filter((item) => {
                if (
                    employerId &&
                    searchName &&
                    searchDateRangeFrom &&
                    searchDateRangeTo
                ) {
                    return (
                        item.E_ID === employerId &&
                        item.Name === searchName &&
                        item.Date >= searchDateRangeFrom &&
                        item.Date <= searchDateRangeTo
                    );
                } else if (
                    searchName &&
                    searchDateRangeFrom &&
                    searchDateRangeTo
                ) {
                    return (
                        item.Name === searchName &&
                        item.Date >= searchDateRangeFrom &&
                        item.Date <= searchDateRangeTo
                    );
                } else if (
                    employerId &&
                    searchDateRangeFrom &&
                    searchDateRangeTo
                ) {
                    return (
                        item.E_ID === employerId &&
                        item.Date >= searchDateRangeFrom &&
                        item.Date <= searchDateRangeTo
                    );
                } else if (searchDateRangeFrom && searchDateRangeTo) {
                    return (
                        item.Date >= searchDateRangeFrom &&
                        item.Date <= searchDateRangeTo
                    );
                } else if (searchName) {
                    return item.Name === searchName;
                } else if (employerId) {
                    return item.E_ID === employerId;
                } else {
                    return false;
                }
            });

            setPermanentlog(data);
            localStorage.setItem("mydata", JSON.stringify(data));
        }
    }, [
        allLogs,
        employerId,
        searchName,
        searchDateRangeFrom,
        searchDateRangeTo,
    ]);

    useEffect(() => {
        axios
            .get(process.env.REACT_APP_API + "/permanent_log/APILimited")
            .then(({ data }) => {
                setAllLogs(data);
                setPermanentlog(data.slice(0, 50));
            });
    }, []);

    const lastPermanentlog = number * PermanentlogPerPage;
    const firstPermanentlog = lastPermanentlog - PermanentlogPerPage;
    const currentPost = permanentlog.slice(firstPermanentlog, lastPermanentlog);
    const PageCount = Math.ceil(permanentlog.length / PermanentlogPerPage);
    const ChangePage = ({ selected }) => {
        console.log(selected);
        setNumber(selected);
    };

    const [minutes, setMinutes] = useState(0);
    const [tardinessMinusCount, setTardinessMinusCount] = useState(0);
    const [tardinessPlussCount, setTardinessPlussCount] = useState(0);
    const [cumulative, setCumulative] = useState(0);

    useEffect(() => {
        let addMinus = 0;
        let addPluss = 0;
        let addMinutes = 0;

        if (permanentlog) {
            permanentlog.forEach((item, key) => {
                if (key === 0) {
                    setCumulative(item.cumulative_work_hour);
                }

                if (item.Tardiness < 0) {
                    addMinus += parseInt(item.Tardiness);
                } else {
                    addPluss += parseInt(item.Tardiness);
                }

                addMinutes += parseInt(item.total_worked_hour_in_minutes);
            });

            setTardinessMinusCount(addMinus);
            setTardinessPlussCount(addPluss);
            setMinutes(addMinutes);
        }
    }, [permanentlog]);

    const [manageemployee, setManageEmployee] = useState("");

    useEffect(() => {
        fetchManageEmployee();
    }, []);

    const fetchManageEmployee = async () => {
        await axios
            .get(process.env.REACT_APP_API + "/employee/APILimited")
            .then(({ data }) => {
                setManageEmployee(data);
            });
    };

    useEffect(() => {
        let intervalId = setInterval(() => {
            setAllLogs();
        }, 1.8e7); // auto call every 5 hour
        return () => clearInterval(intervalId);
    }, []);

    return (
        <div>
            <Form className="container shadow card mb-2 pt-3">
                <Row className="mb-4 ">
                    <Form.Group as={Col} controlId="Designation">
                        <Form.Select
                            aria-label="Default select example"
                            id="E_ID"
                            autoComplete="off"
                            name="E_ID"
                            placeholder="Enter E_ID"
                            onChange={(event) =>
                                setEmployerId(event.target.value)
                            }
                            value={employerId}
                        >
                            <option>Select an option</option>
                            {manageemployee.length > 0 &&
                                manageemployee.map((row) => (
                                    <option
                                        value={row.Employee_ID}
                                        key={row.id}
                                    >
                                        {row.Employee_ID}-{row.employee_name}
                                    </option>
                                ))}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group as={Col} controlId="Salary">
                        <Form.Control
                            type="date"
                            placeholder="Date"
                            value={searchDateRangeFrom}
                            onChange={(e) =>
                                setSearchDateRangeFrom(e.target.value)
                            }
                        />
                    </Form.Group>

                    <Form.Group as={Col} controlId="Salary">
                        <Form.Control
                            type="date"
                            placeholder="Date"
                            value={searchDateRangeTo}
                            onChange={(e) =>
                                setSearchDateRangeTo(e.target.value)
                            }
                        />
                    </Form.Group>
                </Row>
            </Form>
            <div className="d-flex d-flex justify-content-end mb-2 ">
                <PDFDownloadLink
                    document={<MyDocument permanentlog={permanentlog} />}
                    fileName="attendance log data"
                >
                    <button className="btn btn btn-primary ms-2" type="button">
                        <i class="bi bi-file-pdf"></i> PDF
                    </button>
                </PDFDownloadLink>
                <ExportToExcel
                    class="mr-5"
                    apiData={permanentlog}
                    fileName={fileName}
                />
                <CSVLink class="btn btn-primary ms-2" data={permanentlog}>
                    <i class="bi bi-filetype-csv"></i> CSV
                </CSVLink>
            </div>

            <div class="d-flex d-flex justify-content-end mb-2 ">
                <button class="btn btn btn-primary ms-2" type="button">
                    <i class="bi bi-clock yellow-color"></i> Total days :
                    {permanentlog.length}
                </button>
                <button class="btn btn btn-primary ms-2" type="button">
                    <i class="bi bi-clock blue-color"></i> Office Min :{" "}
                    {permanentlog.length * 540}
                </button>
                <button class="btn btn btn-primary ms-2" type="button">
                    <i class="bi bi-clock teal-color"></i> Minutes :{minutes}
                </button>
                <button class="btn btn btn-primary ms-2">
                    <i class="bi bi-clock green-color"></i> Tardiness :
                    {tardinessMinusCount}, {tardinessPlussCount}
                </button>
                <button class="btn btn btn-primary ms-2">
                    <i class="bi bi-clock red-color"></i> Cumulative :
                    {cumulative}
                </button>
            </div>

            {/* <div className="d-flex d-flex justify-content-end mb-2 ">
                 <Button class="btn btn-primary ms-2">
                Tardiness :   Minutes :
                </Button>
            </div> */}
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>SL</th>
                        <th>Employee ID</th>
                        <th>First Name</th>
                        <th>In Time</th>
                        <th>Out Time</th>
                        <th>Date</th>
                        <th>Tardiness</th>
                        <th>Minutes</th>
                        <th>Cumulative</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {currentPost.map((row) => {
                        return (
                            <tr>
                                <td>{row.ID}</td>
                                <td>{row.E_ID}</td>
                                <td>{row.Name}</td>
                                <td>{row.InTime} AM</td>
                                <td>{row.OutTime} PM</td>
                                <td>{row.Date}</td>
                                <td>{row.Tardiness}</td>
                                <td>{row.total_worked_hour_in_minutes}</td>
                                <td>{row.cumulative_work_hour}</td>
                                <td>
                                    <Link
                                        to={`/PermanentLog/edit/${row.ID}`}
                                        className="btn btn-success ms-1 manageBranch"
                                    >
                                        {" "}
                                        <i class="fas fa-edit"> Edit </i>
                                    </Link>
                                </td>
                            </tr>
                        );
                    })}

                    <div style={{ marginLeft: "800%" }}>
                        {currentPost.length === 0 && (
                            <div class="text-center">
                                <div
                                    class="spinner-border text-center"
                                    role="status"
                                >
                                    <span class="visually-hidden">
                                        Loading...
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                </tbody>
            </Table>
            <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                pageCount={PageCount}
                onPageChange={ChangePage}
                containerClassName={"paginationBttns"}
                activeClassName={"paginationActive"}
                disableInitialCallback={true}
                initialPage={1}
            ></ReactPaginate>
        </div>
    );
}
