import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

export default function Edit() {
    const navigate = useNavigate();

    const { Employee_ID } = useParams();
    const [Employee_IDS, setEmployee_ID] = useState("");
    const [employee_name, setEmployee_name] = useState("");
    const [InTime, setInTime] = useState("");
    const [OutTime, setOutTime] = useState("");
    const [Surname, setSurname] = useState("");
    const [Gender, setGender] = useState("");
    const [Register_Date, setRegister_Date] = useState("");
    const [branch, setBranch] = useState("");
    const [designation, setDesignation] = useState("");
    const [department, setDepartment] = useState("");
    const [location, setLocation] = useState("");
    const [address, setAddress] = useState("");
    const [nid, setNid] = useState("");
    const [phone, setPhone] = useState("");
    const [date_of_birth, setDateofbirth] = useState("");
    const [status, setStatus] = useState("");

    const [validationError, setValidationError] = useState({});

    useEffect(() => {
        fetchBranch();
    }, []);

    const fetchBranch = async () => {
        await axios
            .get(
                `${process.env.REACT_APP_API}/employee/APILimited/${Employee_ID}`
            )
            .then(({ data }) => {
                const { Employee_ID } = data[0];
                const { employee_name } = data[0];
                const { InTime } = data[0];
                const { OutTime } = data[0];
                const { Surname } = data[0];
                const { Register_Date } = data[0];
                const { Gender } = data[0];
                const { branch } = data[0];
                const { designation } = data[0];
                const { department } = data[0];
                const { location } = data[0];
                const { address } = data[0];
                const { nid } = data[0];
                const { phone } = data[0];
                const { date_of_birth } = data[0];
                const { status } = data[0];

                setEmployee_ID(Employee_ID);
                setEmployee_name(employee_name);
                setInTime(InTime);
                setOutTime(OutTime);
                setSurname(Surname);
                setGender(Gender);
                setRegister_Date(Register_Date);
                setBranch(branch);
                setDesignation(designation);
                setDepartment(department);
                setLocation(location);
                setAddress(address);
                setNid(nid);
                setPhone(phone);
                setDateofbirth(date_of_birth);
                setStatus(status);
            })
            .catch(({ response: { data } }) => {
                Swal.fire({
                    text: data.message,
                    icon: "error",
                });
            });
    };

    const [departments, setManageDepartment] = useState("");

    useEffect(() => {
        fetchManageDepartment();
    }, []);

    const fetchManageDepartment = async () => {
        await axios
            .get(process.env.REACT_APP_API + "/departments/APILimited")
            .then(({ data }) => {
                setManageDepartment(data);
            });
    };

    const [designations, setManageDesignation] = useState("");

    useEffect(() => {
        fetchManageDesignation();
    }, []);

    const fetchManageDesignation = async () => {
        await axios
            .get(process.env.REACT_APP_API + "/designation/APILimited")
            .then(({ data }) => {
                setManageDesignation(data);
            });
    };

    const [branchs, setManageBranch] = useState("");

    useEffect(() => {
        fetchManageBranch();
    }, []);

    const fetchManageBranch = async () => {
        await axios
            .get(process.env.REACT_APP_API + "/branch/APILimited")
            .then(({ data }) => {
                setManageBranch(data);
            });
    };


    const [locations, setManageLocation] = useState("");

    useEffect(() => {
        fetchManageLocation();
    }, []);

    const fetchManageLocation = async () => {
        await axios
            .get(process.env.REACT_APP_API + "/location/APILimited")
            .then(({ data }) => {
                setManageLocation(data);
            });
    };

    const updateEmployeeInfo = async (e) => {
        e.preventDefault();

        await axios
            .post(
                `${process.env.REACT_APP_API}/employee/APILimited/${Employee_ID}`,
                {
                    Employee_ID: Employee_ID,
                    employee_name: employee_name,
                    InTime: InTime,
                    OutTime: OutTime,
                    Gender: Gender,
                    Register_Date: Register_Date,
                    Surname: Surname,
                    branch: branch,
                    designation: designation,
                    department: department,
                    location: location,
                    address: address,
                    nid: nid,
                    phone: phone,
                    date_of_birth: date_of_birth,
                    status: status,
                }
            )
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    timer: 1500,
                    text: data.message,
                });
                navigate("/manageeemployee");
            })
            .catch(({ response }) => {
                if (response.status === 422) {
                    setValidationError(response.data.errors);
                } else {
                    Swal.fire({
                        text: response.data.message,
                        icon: "error",
                    });
                }
            });
    };

    const [postImage, setPostImage] = useState({
        logo: "",
    });

    const url = process.env.REACT_APP_API + "/employee/APILimited/API2206021";
    const createImage = (newImage) => axios.post(url, newImage);

    const createPost = async (post) => {
        try {
            await createImage(post);
            Swal.fire({
                icon: "success",
                timer: 1500,
                text: post.message,
            });
        } catch (error) {
            console.log(error.message);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        createPost(postImage);
    };

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };
    const handleFileUpload = async (e) => {
        const file = e.target.files[0];
        const base64 = await convertToBase64(file);
        setPostImage({ ...postImage, logo: base64 });
    };




    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 col-sm-12 col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Update Eemployee</h4>
                            <hr />
                            <div className="form-wrapper">
                                {Object.keys(validationError).length > 0 && (
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="alert alert-danger">
                                                <ul className="mb-0">
                                                    {Object.entries(
                                                        validationError
                                                    ).map(([key, value]) => (
                                                        <li key={key}>
                                                            {value}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {/* <Form
                                    onSubmit={handleSubmit}
                                    className="container card mb-2 pt-3"
                                >
                                    <Row className="mb-3">
                                        <Form.Group
                                            as={Col}
                                            controlId="Description"
                                        >
                                            <Form.Label>Photos</Form.Label>
                                            <Form.Control
                                                type="file"
                                                required
                                                label="Image"
                                                name="logo"
                                                accept=".jpeg, .png, .jpg"
                                                onChange={(e) =>
                                                    handleFileUpload(e)
                                                }
                                                placeholder="Photos"
                                            />
                                        </Form.Group>
                                    </Row>

                                    <Form.Group as={Row} className="mb-3">
                                        <Col sm={{ span: 0, offset: 20 }}>
                                            <Button
                                                variant="secondary"
                                                type="submit"
                                            >
                                                <i class="fa fa-save"></i>{" "}
                                                Update Photos
                                            </Button>
                                        </Col>
                                    </Form.Group>
                                </Form> */}

                                <Form
                                    onSubmit={updateEmployeeInfo}
                                    className="container card mb-2 pt-3"
                                >
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="Name">
                                                <Form.Label>
                                                    Employee ID
                                                </Form.Label>

                                                <Form.Control
                                                    type="text"
                                                    required
                                                    value={Employee_IDS}
                                                    onChange={(event) => {
                                                        setEmployee_ID(
                                                            event.target.value
                                                        );
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="Name">
                                                <Form.Label>
                                                    Employee Name
                                                </Form.Label>

                                                <Form.Control
                                                    type="text"
                                                    required
                                                    value={employee_name}
                                                    onChange={(event) => {
                                                        setEmployee_name(
                                                            event.target.value
                                                        );
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Form.Group controlId="Name">
                                                <Form.Label>InTime</Form.Label>

                                                <Form.Control
                                                    type="text"
                                                    required
                                                    value={InTime}
                                                    onChange={(event) => {
                                                        setInTime(
                                                            event.target.value
                                                        );
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="Name">
                                                <Form.Label>OutTime</Form.Label>

                                                <Form.Control
                                                    type="text"
                                                    required
                                                    value={OutTime}
                                                    onChange={(event) => {
                                                        setOutTime(
                                                            event.target.value
                                                        );
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Form.Group as={Col} controlId="Gender">
                                            <Form.Label>Gender</Form.Label>
                                            <Form.Select
                                                aria-label="Default select example"
                                                type="text"
                                                required
                                                value={Gender}
                                                onChange={(event) => {
                                                    setGender(
                                                        event.target.value
                                                    );
                                                }}
                                            >
                                                <option value="Male">
                                                    Male
                                                </option>
                                                <option value="Female">
                                                    Female
                                                </option>
                                            </Form.Select>
                                        </Form.Group>
                                        <Col>
                                            <Form.Group controlId="date_of_birth">
                                                <Form.Label>DOB</Form.Label>

                                                <Form.Control
                                                    type="date"
                                                    required
                                                    value={date_of_birth}
                                                    onChange={(event) => {
                                                        setDateofbirth(
                                                            event.target.value
                                                        );
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Form.Group controlId="Name">
                                                <Form.Label>Surname</Form.Label>

                                                <Form.Control
                                                    type="text"
                                                    required
                                                    value={Surname}
                                                    onChange={(event) => {
                                                        setSurname(
                                                            event.target.value
                                                        );
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            {/* <Form.Group controlId="Name">
                                                <Form.Label>Branch</Form.Label>

                                                <Form.Control
                                                    type="text"
                                                    required
                                                    value={branch}
                                                    onChange={(event) => {
                                                        setBranch(
                                                            event.target.value
                                                        );
                                                    }}
                                                />
                                            </Form.Group> */}
                                            <Form.Group>
                                                <Form.Label>Branch </Form.Label>
                                                <Form.Select
                                                    defaultValue="Choose..."
                                                    id="designation"
                                                    value={branch}
                                                    onChange={(event) => {
                                                        setBranch(
                                                            event.target.value
                                                        );
                                                    }}
                                                >
                                                    <option disabled >
                                                        Choose...

                                                    </option>

                                                    {branchs.length > 0 &&
                                                        branchs.map(
                                                            (row) => (
                                                                <option
                                                                 onChange={(e) => setBranch(e.target.value)}
                                                                    value={
                                                                        row.State
                                                                    }
                                                                    key={
                                                                        row.State
                                                                    }
                                                                >
                                                                    {
                                                                        row.State
                                                                    }
                                                                </option>
                                                            )
                                                        )}
                                                </Form.Select>


                                                {/* <Form.Select
                                                    defaultValue="Choose..."
                                                    id="branch"
                                                >
                                                    <option disabled>
                                                        Choose...
                                                    </option>

                                                    {branchs.length > 0 &&
                                                        branchs.map((row) => (
                                                            <option
                                                                value={
                                                                    row.Country
                                                                }
                                                                key={
                                                                    row.Country
                                                                }
                                                            >
                                                                {row.Country},
                                                                {row.State}
                                                            </option>
                                                        ))}
                                                </Form.Select> */}


                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="Name">
                                                <Form.Label>
                                                    Designation
                                                </Form.Label>
                                                <Form.Select
                                                    defaultValue="Choose..."
                                                    id="designation"
                                                    value={designation}
                                                    onChange={(event) => {
                                                        setDesignation(
                                                            event.target.value
                                                        );
                                                    }}
                                                >
                                                    <option disabled >
                                                        Choose...

                                                    </option>

                                                    {designations.length > 0 &&
                                                        designations.map(
                                                            (row) => (
                                                                <option
                                                                 onChange={(e) => setDesignation(e.target.value)}
                                                                    value={
                                                                        row.designation
                                                                    }
                                                                    key={
                                                                        row.Designation
                                                                    }
                                                                >
                                                                    {
                                                                        row.Designation
                                                                    }
                                                                </option>
                                                            )
                                                        )}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>

                                        <Col>
                                            <Form.Group controlId="Name">
                                                <Form.Label>
                                                    Department
                                                </Form.Label>
                                                <Form.Select
                                                    defaultValue="Choose..."
                                                    id="department"
                                                    value={department}
                                                    onChange={(event) => {
                                                        setDepartment(
                                                            event.target.value
                                                        );
                                                    }}
                                                >
                                                    <option disabled>
                                                        Choose...
                                                    </option>

                                                    {departments.length > 0 &&
                                                        departments.map(
                                                            (row) => (
                                                                <option
                                                                onChange={(e) => setDepartment(e.target.value)}
                                                                    value={
                                                                        row.department
                                                                    }
                                                                    key={
                                                                        row.Department
                                                                    }
                                                                >
                                                                    {
                                                                        row.Department
                                                                    }
                                                                </option>
                                                            )
                                                        )}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Form.Group controlId="location">
                                                <Form.Label>
                                                    Location
                                                </Form.Label>
                                                <Form.Select
                                                    defaultValue="Choose..."
                                                    id="department"
                                                    value={location}
                                                    onChange={(event) => {
                                                        setLocation(
                                                            event.target.value
                                                        );
                                                    }}
                                                >
                                                    <option disabled>
                                                        Choose...
                                                    </option>

                                                    {locations.length > 0 &&
                                                        locations.map(
                                                            (row) => (
                                                                <option
                                                                onChange={(e) => setLocation(e.target.value)}
                                                                    value={
                                                                        row.location_name
                                                                    }
                                                                    key={
                                                                        row.location_name
                                                                    }
                                                                >
                                                                    {
                                                                        row.location_name
                                                                    }
                                                                </option>
                                                            )
                                                        )}
                                                </Form.Select>

                                                {/* <Form.Control
                                                    type="text"
                                                    required
                                                    value={location}
                                                    onChange={(event) => {
                                                        setLocation(
                                                            event.target.value
                                                        );
                                                    }}
                                                /> */}
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="address">
                                                <Form.Label>Address</Form.Label>

                                                <Form.Control
                                                    type="text"
                                                    required
                                                    value={address}
                                                    onChange={(event) => {
                                                        setAddress(
                                                            event.target.value
                                                        );
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Form.Group controlId="phone">
                                                <Form.Label>Phone</Form.Label>

                                                <Form.Control
                                                    type="text"
                                                    required
                                                    value={phone}
                                                    onChange={(event) => {
                                                        setPhone(
                                                            event.target.value
                                                        );
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col>
                                            <Form.Group controlId="nid">
                                                <Form.Label>NID</Form.Label>

                                                <Form.Control
                                                    type="text"
                                                    required
                                                    value={nid}
                                                    onChange={(event) => {
                                                        setNid(
                                                            event.target.value
                                                        );
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Form.Group controlId="Name">
                                                <Form.Label>
                                                    Register Date
                                                </Form.Label>

                                                <Form.Control
                                                    type="date"
                                                    required
                                                    value={Register_Date}
                                                    onChange={(event) => {
                                                        setRegister_Date(
                                                            event.target.value
                                                        );
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Form.Group as={Col} controlId="status">
                                            <Form.Label>Status</Form.Label>
                                            <Form.Select
                                                aria-label="Default select example"
                                                type="text"
                                                required
                                                value={status}
                                                onChange={(event) => {
                                                    setStatus(
                                                        event.target.value
                                                    );
                                                }}
                                            >
                                                <option value="0">
                                                    Active
                                                </option>
                                                <option value="1">
                                                    Inactive
                                                </option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Row>

                                    <Form.Group as={Row} className="mb-3 mt-3">
                                        <Col sm={{ span: 2, offset: 20 }}>
                                            <Button
                                                variant="secondary"
                                                type="submit"
                                            >
                                                <i class="fa fa-save"></i>{" "}
                                                Update
                                            </Button>
                                        </Col>
                                    </Form.Group>
                                </Form>

                                {/* <Form
                                    onSubmit={handleSubmit}
                                    className="container card mb-2 pt-3"
                                >
                                    <Row className="mb-3">
                                        <Form.Group
                                            as={Col}
                                            controlId="Description"
                                        >
                                            <Form.Label>Upload profile picture</Form.Label>
                                            <Form.Control
                                                type="file"
                                                required
                                                label="Image"
                                                name="Image_Location"
                                                accept=".jpeg, .png, .jpg"
                                                onChange={(e) =>
                                                    handleFileUpload(e)
                                                }
                                                placeholder="Photos"
                                            />
                                        </Form.Group>
                                    </Row>

                                    <Form.Group as={Row} className="mb-3">
                                        <Col sm={{ span: 0, offset: 20 }}>
                                            <Button
                                                variant="secondary"
                                                type="submit"
                                            >
                                                <i class="fas fa-camera"></i>{" "}
                                                Upload
                                            </Button>
                                        </Col>
                                    </Form.Group>
                                </Form> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
