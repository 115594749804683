import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

export default function AddCompany() {
    const navigate = useNavigate();
    const [validationError, setValidationError] = useState({});

    const createDepartment = async (e) => {
        e.preventDefault();

        var Department = document.getElementById("Department").value;
        var Departments_Details = document.getElementById(
            "Departments_Details"
        ).value;

        const postData = {
            Department,
            Departments_Details,
        };

        await axios
            .post(
              process.env.REACT_APP_API + "/departments/APILimited",
                postData,
                {}
            )
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    text: data.message,
                });
                navigate("/department");
            })
            .catch(({ response }) => {
                if (response.status === 422) {
                    setValidationError(response.data.errors);
                } else {
                    Swal.fire({
                        text: response.data.message,
                        icon: "error",
                    });
                }
            });
    };

    return (
        <div>
            <Form className="container card mb-2 pt-3">
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="componyname">
                        <Form.Label>Compony Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Advanced Project Integration"
                        />
                    </Form.Group>

                    <Form.Group as={Col} controlId="address">
                        <Form.Label>Company Address</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="House -4 Road 23/A Block B Banani Dhaka 1213 Bangladesh "
                        />
                    </Form.Group>
                </Row>

                <Row className="mb-3">
                    <Form.Group as={Col} controlId="phonenumber">
                        <Form.Label>Company Phone Number</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="+88-02 55035911"
                        />
                    </Form.Group>

                    <Form.Group as={Col} controlId="emailaddress">
                        <Form.Label>Company Email Address</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="hello@api.net.bd"
                        />
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="logo">
                        <Form.Label>Company Logo</Form.Label>
                        <Form.Control type="file" />
                    </Form.Group>
                </Row>

                <Form.Group as={Row} className="mb-3">
                    <Col sm={{ span: 0, offset: 20 }}>
                        <Button variant="secondary" type="submit">
                            Save
                        </Button>
                    </Col>
                </Form.Group>
            </Form>
        </div>
    );
}
