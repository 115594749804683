import Table from "react-bootstrap/Table";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import MyDocument from "./MyDocument";
import { ExportToExcel } from "./ExportToExcel";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CSVLink } from "react-csv";
import ReactPaginate from "react-paginate";

export default function ManageDepartment() {

    const [searchText, setSearchText] = useState("");
    const [number, setNumber] = useState(1); // No of pages
    const [PerPage] = useState(10);
    const [manageDepartment, setManageDepartment] = useState([]);
    const fileName = "Branch"; // here enter filename for your excel file

    useEffect(() => {
        fetchDepartment();
    }, []);

    const fetchDepartment = async () => {
        await axios
            .get(
              process.env.REACT_APP_API + "/departments/APILimited"
            )
            .then(({ data }) => {
                setManageDepartment(data);
            });
    };

    const deleteDepartment = async (ID) => {
        const isConfirm = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            return result.isConfirmed;
        });
        if (!isConfirm) {
            return;
        }
        await axios
            .delete(
                `https://362jmo35e9.execute-api.ca-central-1.amazonaws.com/pod/APILimited/${ID}`
            )
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    timer: 1500,
                    showConfirmButton: false,
                    text: data.message,
                });
                fetchDepartment();
            })
            .catch(({ response: { data } }) => {
                Swal.fire({
                    text: data.message,
                    icon: "error",
                });
            });
    };

    const last = number * PerPage;
    const first = last - PerPage;
    const currentPost = manageDepartment.slice(first, last);
    const PageCount = Math.ceil(manageDepartment.length / PerPage);
    const ChangePage = ({ selected }) => {
        console.log(selected);
        setNumber(selected);
    };

    // exclude column list from filter
    const excludeColumns = ["id", "date", "name", "e_id"];
    // handle change event of search input
    const handleChange = (value) => {
        setSearchText(value);
        filterData(value);
    };
    // filter records by search text
    const filterData = (value) => {
        const lowercasedValue = value.toLowerCase().trim();
        if (lowercasedValue === "") setManageDepartment(manageDepartment);
        else {
            const filteredData = manageDepartment.filter((item) => {
                return Object.keys(item).some((key) =>
                    excludeColumns.includes(key)
                        ? false
                        : item[key]
                              .toString()
                              .toLowerCase()
                              .includes(lowercasedValue)
                );
            });
            setManageDepartment(filteredData);
        }
    };

    return (
        <div>
            <div className="col-lg-12 bg-white shadow rounded mb-4 border-1 border-secondary">
                <div className="d-flex d-flex justify-content-between mb-2 ">
                    <div className="">
                        <Form className="container card mb-2 mt-2 bg-transparent border-0 m-0">
                            <Row className="">
                                <Form.Group as={Col}>
                                    <div class="row g-3 align-items-center">
                                        <div class="col-auto">
                                            <label
                                                for="search"
                                                class="col-form-label"
                                            >
                                                Search
                                            </label>
                                        </div>
                                        <div class="col-auto">
                                            <input
                                                type="text"
                                                placeholder="search..."
                                                value={searchText}
                                                onChange={(e) =>
                                                    handleChange(e.target.value)
                                                }
                                                id="search"
                                                class="form-control"
                                                aria-describedby="passwordHelpInline"
                                            />
                                        </div>
                                    </div>
                                </Form.Group>
                            </Row>
                        </Form>
                    </div>
                    <div className=" mt-2">
                        <PDFDownloadLink
                            document={<MyDocument />}
                            fileName="Department"
                        >
                            <button
                                className="btn btn btn-primary ms-2"
                                type="button"
                            >
                                <i class="bi bi-file-pdf"></i> PDF
                            </button>
                        </PDFDownloadLink>
                        <ExportToExcel
                            class="mr-5"
                            apiData={manageDepartment}
                            fileName={fileName}
                        />
                        <CSVLink
                            class="btn btn-primary ms-2"
                            data={manageDepartment}
                        >
                            <i class="bi bi-filetype-csv"></i> CSV
                        </CSVLink>
                    </div>
                </div>
            </div>

            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>SL</th>
                        <th>Department</th>
                        <th>Departments Details</th>
                        <th>Action</th>
                    </tr>
                </thead>

                <tbody>
                    {currentPost.length > 0 &&
                        currentPost.map((row, key) => (
                            <tr key={key}>
                                <td>{key + 1}</td>
                                <td>{row.Department}</td>
                                <td>{row.Departments_Details}</td>

                                <td>
                                    <Link
                                        to={`/department/edit/${row.ID}`}
                                        className="btn btn-success ms-1 manageBranch"
                                    >
                                     <i class="fas fa-edit"> Edit </i>
                                    </Link>

                                    <Link
                                        onClick={() => deleteDepartment(row.ID)}
                                        className="btn btn-success ms-1 manageBranch"
                                    >
                                        <i class="fa fa-trash"> Delete </i>
                                    </Link>
                                </td>
                            </tr>
                        ))}

                    <div style={{ marginLeft: "400%" }}>
                        {currentPost.length === 0 && (
                            <div class="text-center">
                                <div
                                    class="spinner-border text-center"
                                    role="status"
                                >
                                    <span class="visually-hidden">
                                        Loading...
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                </tbody>
            </Table>
            <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                pageCount={PageCount}
                onPageChange={ChangePage}
                containerClassName={"paginationBttns"}
                activeClassName={"paginationActive"}
                disableInitialCallback={true}
                initialPage={1}
            ></ReactPaginate>
        </div>
    );
}
