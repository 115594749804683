import Table from "react-bootstrap/Table";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

import ReactPaginate from "react-paginate";

export default function ManagePaySlipSettings() {
    const [number, setNumber] = useState(1); // No of pages
    const [PerPage] = useState(10);
    const [manageSignatory, setmanageSignatory] = useState([]);

    useEffect(() => {
        fetchSignatory();
    }, []);

    const fetchSignatory = async () => {
        await axios
            .get(process.env.REACT_APP_API + "/signatory/APILimited/")
            .then(({ data }) => {
                setmanageSignatory(data);
            });
    };

    const last = number * PerPage;
    const first = last - PerPage;
    const currentPost = manageSignatory.slice(first, last);
    const PageCount = Math.ceil(manageSignatory.length / PerPage);
    const ChangePage = ({ selected }) => {
        console.log(selected);
        setNumber(selected);
    };

    const deleteSignatory = async (ID) => {
        const isConfirm = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            return result.isConfirmed;
        });
        if (!isConfirm) {
            return;
        }
        await axios
            .delete(`${process.env.REACT_APP_API}/signatory/APILimited/${ID}`)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    text: data.message,
                });
                fetchSignatory();
            })
            .catch(({ response: { data } }) => {
                Swal.fire({
                    text: data.message,
                    icon: "error",
                });
            });
    };

    return (
        <div>
            <div className="card mt-4">
                <div className="card-body">
                    <h3 className="card-title pt-3 ">Manage Signatory</h3>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Designation</th>
                                <th>Action</th>
                            </tr>
                        </thead>



                        <tbody>
                            {currentPost.length > 0 &&
                                currentPost.map((row, key) => (
                                    <tr key={key}>
                                        <td>{row.Name}</td>
                                        <td>{row.designation}</td>
                                        <td>
                                            <Link
                                                onClick={() =>
                                                    deleteSignatory(row.ID)
                                                }
                                                className="btn btn-success ms-1 manageBranch"
                                            >
                                                <i class="fa fa-trash">
                                                    {" "}
                                                    Delete{" "}
                                                </i>
                                            </Link>
                                        </td>
                                    </tr>
                                ))}


                        </tbody>
                    </Table>

                </div>
            </div>
        </div>
    );
}
