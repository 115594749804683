import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
export default function Employee() {
  const navigate = useNavigate();
  const [validationError, setValidationError] = useState({});

  // 🧑‍💻😊💀❤️🔥🤖 Start  add New Employee 🧑‍💻😊💀❤️🔥🤖
  const createEmployee = async (e) => {
    e.preventDefault();

    var Employee_ID = document.getElementById("employee_id").value;
    var employee_name = document.getElementById("employee_name").value;
    var InTime = document.getElementById("InTime").value;
    var OutTime = document.getElementById("OutTime").value;
    var Gender = document.getElementById("Gender").value;
    var date_of_birth = document.getElementById("date_of_birth").value;
    var Surname = document.getElementById("Surname").value;
    var branch = document.getElementById("branch").value;
    var designation = document.getElementById("designation").value;
    var department = document.getElementById("department").value;
    var location = document.getElementById("location").value;
    var address = document.getElementById("address").value;
    var nid = document.getElementById("nid").value;
    var phone = document.getElementById("phone").value;
    var Register_Date = document.getElementById("Register_Date").value;
    var status = document.getElementById("status").value;

    const postData = {
      Employee_ID,
      employee_name,
      InTime,
      OutTime,
      date_of_birth,
      Gender,
      Surname,
      branch,
      designation,
      department,
      location,
      address,
      nid,
      phone,
      Register_Date,
      status,
    };

    await axios
      .post(process.env.REACT_APP_API + "/employee/APILimited", postData, {})
      .then(({ data }) => {
        Swal.fire({
          icon: "success",
          timer: 1500,
          text: data.message,
        });
        navigate("/employee");
      })
      .catch(({ response }) => {
        if (response.status === 422) {
          setValidationError(response.data.errors);
        } else {
          Swal.fire({
            text: response.data.message,
            icon: "error",
          });
        }
      });
  };

  const [department, setManageDepartment] = useState("");

  useEffect(() => {
    fetchManageDepartment();
  }, []);

  const fetchManageDepartment = async () => {
    await axios
      .get(process.env.REACT_APP_API + "/departments/APILimited")
      .then(({ data }) => {
        setManageDepartment(data);
      });
  };

  const [designation, setManageDesignation] = useState("");

  useEffect(() => {
    fetchManageDesignation();
  }, []);

  const fetchManageDesignation = async () => {
    await axios
      .get(process.env.REACT_APP_API + "/designation/APILimited")
      .then(({ data }) => {
        setManageDesignation(data);
      });
  };

  const [branch, setManageBranch] = useState("");

  useEffect(() => {
    fetchManageBranch();
  }, []);

  const fetchManageBranch = async () => {
    await axios
      .get(process.env.REACT_APP_API + "/branch/APILimited")
      .then(({ data }) => {
        setManageBranch(data);
      });
  };

  const [entity, setManageEntity] = useState("");

  useEffect(() => {
    fetchManageEntity();
  }, []);

  const fetchManageEntity = async () => {
    await axios
      .get(process.env.REACT_APP_API + "/fixed_earning_entity/APILimited")
      .then(({ data }) => {
        setManageEntity(data);
      });
  };

  const [location, setManageLocation] = useState("");

  useEffect(() => {
    fetchManageLocation();
  }, []);

  const fetchManageLocation = async () => {
    await axios
      .get(process.env.REACT_APP_API + "/location/APILimited")
      .then(({ data }) => {
        setManageLocation(data);
      });
  };

  //End  add New Employee

  //🧑‍💻😊💀❤️🔥🤖 EmployeeID  Dropdown Menu 🧑‍💻😊💀❤️🔥🤖
  const [manageDesignations, setManageEmployees] = useState([]);

  const [manageemployee, setManageEmployee] = useState("");

  useEffect(() => {
    fetchManageEmployee();
  }, []);

  const fetchManageEmployee = async () => {
    await axios
      .get(process.env.REACT_APP_API + "/employee/APILimited")
      .then(({ data }) => {
        setManageEmployee(data);
      });
  };
  //🧑‍💻😊💀❤️🔥🤖 EmployeeID  Dropdown Menu 🧑‍💻😊💀❤️🔥🤖

  //🧑‍💻😊💀❤️🔥🤖start  createMonthlyEarning 🧑‍💻😊💀❤️🔥🤖
  const createMonthlyEarning = async (e) => {
    e.preventDefault();

    var E_ID = document.getElementById("E_ID").value;
    var Entity = document.getElementById("Entity").value;
    var Type = document.getElementById("Type").value;
    //var description = document.getElementById("description").value;
    var Amount = document.getElementById("Amount").value;
    //var Date = document.getElementById("Date").value;

    const postData = {
      E_ID,
      Entity,
      Type,
      //  description,
      Amount,
      // Date,
    };

    await axios
      .post(
        process.env.REACT_APP_API + "/fixed_earning/APILimited",
        postData,
        {}
      )
      .then(({ data }) => {
        Swal.fire({
          icon: "success",
          text: data.message,
        });
        navigate("/employee");
      })
      .catch(({ response }) => {
        if (response.status === 422) {
          setValidationError(response.data.errors);
        } else {
          Swal.fire({
            text: response.data.message,
            icon: "error",
          });
        }
      });
  };
  //🧑‍💻😊💀❤️🔥🤖End createMonthlyEarning 🧑‍💻😊💀❤️🔥🤖

  //🧑‍💻😊💀❤️🔥🤖Start company createImage base64 🧑‍💻😊💀❤️🔥🤖
  const [postImage, setPostImage] = useState({
    logo: "",
  });

  const url = process.env.REACT_APP_API + "/company/user/1";
  const createImage = (newImage) => axios.post(url, newImage);

  const createPost = async (post) => {
    try {
      await createImage(post);
      Swal.fire({
        icon: "success",
        text: post.message,
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    createPost(postImage);
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    setPostImage({ ...postImage, logo: base64 });
  };

  //🧑‍💻😊💀❤️🔥🤖End  company createImage base64  🧑‍💻😊💀❤️🔥🤖

  return (
    <div>
      <Tabs
        defaultActiveKey="info"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="info" title="Employee Info">
          <Form onSubmit={createEmployee} className="container card mb-2 pt-3">
            <h4 className="card-title">Add New Employee</h4>
            <hr />
            <Row className="mb-3">
              <Form.Group as={Col} controlId="employee_id">
                <Form.Label>Employee ID *</Form.Label>
                <Form.Control
                  type="text"
                  required
                  id="employee_id"
                  placeholder="API2007003"
                />
              </Form.Group>

              <Form.Group as={Col} controlId="employee_name">
                <Form.Label>Employee Name *</Form.Label>
                <Form.Control
                  type="text"
                  required
                  placeholder="Md.Mizanur Rahaman Sobuj"
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="InTime">
                <Form.Label>In Time *</Form.Label>
                <Form.Control
                  type="text"
                  id="InTime"
                  required
                  placeholder="09:00:00"
                />
              </Form.Group>

              <Form.Group as={Col} controlId="OutTime">
                <Form.Label>Out Time *</Form.Label>
                <Form.Control
                  type="text"
                  id="OutTime"
                  required
                  placeholder="18:00:00"
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="Gender">
                <Form.Label>Gender *</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  id="Gender"
                  required
                >
                  <option>Select an option</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other ">Other </option>
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} controlId="date_of_birth">
                <Form.Label>DOB</Form.Label>
                <Form.Control
                  type="date"
                  required
                  id="date_of_birth"
                  placeholder="dob"
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="Surname">
                <Form.Label>Surname *</Form.Label>
                <Form.Control
                  type="text"
                  required
                  id="Surname"
                  placeholder="Surname"
                />
              </Form.Group>

              <Form.Group as={Col} controlId="designation">
                <Form.Label>Designation *</Form.Label>
                <Form.Select defaultValue="Choose..." id="designation">
                  <option disabled>Choose...</option>

                  {designation.length > 0 &&
                    designation.map((row) => (
                      <option value={row.Designation} key={row.Designation}>
                        {row.Designation}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="department">
                <Form.Label>Department *</Form.Label>
                <Form.Select defaultValue="Choose..." id="department">
                  <option disabled>Choose...</option>

                  {department.length > 0 &&
                    department.map((row) => (
                      <option value={row.Department} key={row.Department}>
                        {row.Department}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} controlId="branch">
                <Form.Label>Branch *</Form.Label>
                <Form.Select defaultValue="Choose..." id="branch">
                  <option disabled>Choose...</option>

                  {branch.length > 0 &&
                    branch.map((row) => (
                      <option value={row.Country} key={row.Country}>
                        {row.Country},{row.State}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="location">
                <Form.Label>Location </Form.Label>
                <Form.Select defaultValue="Choose..." id="location">
                  <option disabled>Choose...</option>

                  {location.length > 0 &&
                    location.map((row) => (
                      <option value={row.location_name} key={row.location_name}>
                        {row.location_name}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col} controlId="address">
                <Form.Label>Address *</Form.Label>
                <Form.Control
                  type="text"
                  required
                  id="address"
                  placeholder="Address"
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="nid">
                <Form.Label>NID</Form.Label>
                <Form.Control type="number" id="nid" placeholder="NID" />
              </Form.Group>

              <Form.Group as={Col} controlId="phone">
                <Form.Label>Phone *</Form.Label>
                <Form.Control
                  type="number"
                  required
                  maxlength="4"
                  id="phone"
                  placeholder="Phone"
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="Register_Date">
                <Form.Label>Register Date *</Form.Label>
                <Form.Control
                  type="date"
                  required
                  id="Register_Date"
                  placeholder="Register Date"
                />
              </Form.Group>

              <Form.Group as={Col} controlId="Gender">
                <Form.Label>Status *</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  type="text"
                  id="status"
                  required
                >
                  <option value="0">Active</option>
                  <option value="1">Inactive</option>
                </Form.Select>
              </Form.Group>
            </Row>

            <Form.Group as={Row} className="mb-3">
              <Col sm={{ span: 0, offset: 20 }}>
                <Button variant="secondary" type="submit">
                  <i class="fa fa-save"></i> Add
                </Button>
              </Col>
            </Form.Group>
          </Form>
        </Tab>



        <Tab eventKey="Photos" title="Add Photos">
          <Form onSubmit={handleSubmit} className="container card mb-2 pt-3">
            <h4 className="card-title">Add Photos </h4>
            <hr />

            <Row className="mb-3">
              <Form.Group as={Col} controlId="Description">
                <Form.Label>Photos</Form.Label>
                <Form.Control
                  type="file"
                  required
                  label="Image"
                  name="logo"
                  accept=".jpeg, .png, .jpg"
                  onChange={(e) => handleFileUpload(e)}
                  placeholder="Description"
                />
              </Form.Group>
            </Row>

            <Form.Group as={Row} className="mb-3">
              <Col sm={{ span: 0, offset: 20 }}>
                <Button variant="secondary" type="submit">
                  <i class="fa fa-save"></i> Add
                </Button>
              </Col>
            </Form.Group>
          </Form>

          {/* <form onSubmit={handleSubmitdd}>
                        <input
                            type="file"
                            label="Image"
                            name="logo"
                            accept=".jpeg, .png, .jpg"
                            onChange={(e) => handleFileUpload(e)}
                        />

                        <button>Submit</button>
                    </form> */}
        </Tab>

        <Tab eventKey="FileManage" title="File Manage">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Add File Management</h4>
              <hr />
              <div className="form-wrapper">
                {Object.keys(validationError).length > 0 && (
                  <div className="row">
                    <div className="col-12">
                      <div className="alert alert-danger">
                        <ul className="mb-0">
                          {Object.entries(validationError).map(
                            ([key, value]) => (
                              <li key={key}>{value}</li>
                            )
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                )}

                <Form>
                  <Row>
                    <Col>
                      <Form.Group controlId="latitude">
                        <Form.Label>File Caption</Form.Label>
                        <Form.Control type="text" id="latitude" required />
                      </Form.Group>
                    </Col>

                  </Row>


                  <Row>
                    <Col>
                      <Form.Group controlId="longitude">
                        <Form.Label>Chose File</Form.Label>
                        <Form.Control type="file" required id="longitude" />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Form.Group as={Row} className="mb-3 mt-3">
                    <Col sm={{ span: 2, offset: 20 }}>
                      <Button variant="secondary" type="submit">
                        <i class="fa fa-save"></i> Add
                      </Button>
                    </Col>
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div>
        </Tab>

        <Tab eventKey="earning" title="Earning/Deduction">
          <Form
            onSubmit={createMonthlyEarning}
            className="container card mb-2 pt-3"
          >
            <h4 className="card-title">Fixed </h4>
            <hr />
            <Row className="mb-3">
              <Form.Group as={Col} controlId="E_ID">
                <Form.Label>Employee ID </Form.Label>
                <Form.Select defaultValue="Choose..." id="E_ID" required>
                  <option disabled>Choose...</option>

                  {manageemployee.length > 0 &&
                    manageemployee.map((row) => (
                      <option value={row.Employee_ID} key={row.Employee_ID}>
                        {row.Employee_ID}-{row.employee_name}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} controlId="Entity">
                <Form.Label>Type</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  id="Type"
                  required
                >
                  <option disabled>Choose...</option>
                  <option value="1">Earning</option>
                  <option value="2">Deduction</option>
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col} controlId="Entity">
                <Form.Label>Entity</Form.Label>
                <Form.Select defaultValue="Choose..." id="Entity" required>
                  <option disabled>Choose...</option>

                  {entity.length > 0 &&
                    entity.map((row) => (
                      <option value={row.entity} key={row.entity}>
                        {row.entity}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="Amount">
                <Form.Label>Amount</Form.Label>
                <Form.Control
                  type="text"
                  id="Amount"
                  required
                  placeholder="Amount"
                />
              </Form.Group>
            </Row>
            {/* <Row className="mb-3">
                            <Form.Group as={Col} controlId="date">
                                <Form.Label>Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    id="Date"
                                    placeholder="date"
                                />
                            </Form.Group>

                            <Row class="mb-4">
                            <From.Group as={COL}</Row>
                        </Row> */}

            <Form.Group as={Row} className="mb-3">
              <Col sm={{ span: 0, offset: 20 }}>
                <Button variant="secondary" type="submit">
                  <i class="fa fa-save"></i> Add
                </Button>
              </Col>
            </Form.Group>
          </Form>
        </Tab>

        {/* <Tab eventKey="others" title="Others" disabled></Tab> */}
      </Tabs>
    </div>
  );
}
