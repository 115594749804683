import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

export default function Edit() {
    const navigate = useNavigate();

    const { ID } = useParams();
    const [entity, setEntity] = useState("");

    const [validationError, setValidationError] = useState({});

    useEffect(() => {
        fetchEntity();
    }, []);

    const fetchEntity = async () => {
        await axios
            .get(`${process.env.REACT_APP_API}/entity/APILimited/${ID}`)
            .then(({ data }) => {
                const { entity } = data[0];

                setEntity(entity);
            })
            .catch(({ response: { data } }) => {
                Swal.fire({
                    text: data.message,
                    icon: "error",
                });
            });
    };

    const updateEntity = async (e) => {
        e.preventDefault();

        await axios
            .post(`${process.env.REACT_APP_API}/entity/APILimited/${ID}`, {
                entity: entity,
            })
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    timer: 1500,
                    text: data.message,
                });
                navigate("/manageentity");
            })
            .catch(({ response }) => {
                if (response.status === 422) {
                    setValidationError(response.data.errors);
                } else {
                    Swal.fire({
                        text: response.data.message,
                        icon: "error",
                    });
                }
            });
    };

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 col-sm-12 col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Update Entity</h4>
                            <hr />
                            <div className="form-wrapper">
                                {Object.keys(validationError).length > 0 && (
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="alert alert-danger">
                                                <ul className="mb-0">
                                                    {Object.entries(
                                                        validationError
                                                    ).map(([key, value]) => (
                                                        <li key={key}>
                                                            {value}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <Form onSubmit={updateEntity}>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="entity">
                                                <Form.Label>
                                                Entity
                                                </Form.Label>

                                                <Form.Control
                                                    type="text"
                                                    required
                                                    value={entity}
                                                    onChange={(event) => {
                                                        setEntity(
                                                            event.target.value
                                                        );
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Form.Group as={Row} className="mb-3 mt-3">
                                        <Col sm={{ span: 2, offset: 20 }}>
                                            <Button
                                                variant="secondary"
                                                type="submit"
                                            >
                                                <i class="fa fa-save"></i>{" "}
                                                Update
                                            </Button>
                                        </Col>
                                    </Form.Group>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
