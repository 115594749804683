import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";

export default function Generated() {


    /*This function will get all the FinalSalaryReport records*/

    const [finalsalaryreport, setmanageFinalsalaryreport] = useState([]);

    useEffect(() => {
        fetchFinalsalaryreport();
    }, []);

    const fetchFinalsalaryreport = async () => {
        await axios
            .get(
              process.env.REACT_APP_API + "/final_salary_report/APILimited"
            )
            .then(({ data }) => {
                //console.log(data)
                setmanageFinalsalaryreport(data);
            });
    };

    const [totalEarnings, setTotalEarnings] = useState(0);
    const [totalDeductions, setTotalDeductions] = useState(0);

    useEffect(() => {
        setTotalEarnings(0);
        setTotalDeductions(0);

        finalsalaryreport.forEach((item) => {
            if (item.status === "active") {
                item.earnings.forEach((earning) => {
                    setTotalEarnings(
                        (totalEarnings) =>
                            parseInt(totalEarnings) + parseInt(earning.amount)
                    );
                });

                item.deductions.forEach((deduction) => {
                    setTotalDeductions(
                        (totalDeductions) =>
                            parseInt(totalDeductions) +
                            parseInt(deduction.amount)
                    );
                });
            }
        });
    }, [finalsalaryreport]);

    const current = new Date();
    current.setMonth(current.getMonth() - 1);
    const previousMonth = current.toLocaleString("default", { month: "long" });

    // console.log(previousMonth); // "September"

    var today = new Date();

    // Getting full month name (e.g. "June")
    var month = today.toLocaleString("default", { month: "long" });
    console.log(month);

    return (
        <div>
            <Link to={"/generated"} className="btn btn-success manageBranch">
                <span>Generated salary for the month of {previousMonth} </span>
            </Link>
            <p className="btn btn-success manageBranch  mt-3">
                Budget for {month} Salary :<span> ৳</span> {totalEarnings}
            </p>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>SL</th>
                        <th>First Name</th>
                        <th>Employee ID</th>
                        <th>Phone</th>
                        <th>Action</th>
                    </tr>
                </thead>

                <tbody>
                    {finalsalaryreport.map((row, key) => {
                        if (row.status === "active") {
                            return (
                                <tr key={key}>
                                    <td>{key++}</td>
                                    <td>{row.employee_name}</td>
                                    <td>{row.E_ID}</td>
                                    <td>{row.phone}</td>
                                    <td>
                                        <Link
                                            to={`/salaryreport/payslip/${row.E_ID}`}
                                            className="btn btn-success manageBranch"
                                        >
                                            <span>Pay Slip</span>
                                        </Link>
                                    </td>
                                </tr>
                            );
                        }
                    })}
                </tbody>
            </Table>
        </div>
    );
}
