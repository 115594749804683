import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

export default function EditCompany() {
  const navigate = useNavigate();

  const { ID } = useParams();
  const [company_name, setCompany_name] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [mobile, setMobile] = useState("");
  const [logo, setLogo] = useState("");

  const [validationError, setValidationError] = useState({});

  useEffect(() => {
    fetchCompany();
  }, []);

  const fetchCompany = async () => {
    await axios
      .get(`${process.env.REACT_APP_API}/company/user/${ID}`)
      .then(({ data }) => {
        const { company_name } = data[0];
        const { address } = data[0];
        const { email } = data[0];
        const { phone } = data[0];
        const { mobile } = data[0];
        const { logo } = data[0];

        setCompany_name(company_name);
        setAddress(address);
        setEmail(email);
        setPhone(phone);
        setMobile(mobile);
        setLogo(logo);
      })
      .catch(({ response: { data } }) => {
        Swal.fire({
          text: data.message,
          icon: "error",
        });
      });
  };

  const updateCompany = async (e) => {
    e.preventDefault();

    await axios
      .post(`${process.env.REACT_APP_API}/company/user/${ID}`, {
        company_name: company_name,
        address: address,
        email: email,
        phone: phone,
        mobile: mobile,
      })
      .then(({ data }) => {
        Swal.fire({
          icon: "success",
          timer: 1500,
          text: data.message,
        });
        navigate("/managecompany");
      })
      .catch(({ response }) => {
        if (response.status === 422) {
          setValidationError(response.data.errors);
        } else {
          Swal.fire({
            text: response.data.message,
            icon: "error",
          });
        }
      });
  };

  const [postImage, setPostImage] = useState({
    logo: "",
  });

  const url = process.env.REACT_APP_API + "/company/user/1";
  const createImage = (newImage) => axios.post(url, newImage);

  const createPost = async (post) => {
    try {
      await createImage(post);
      Swal.fire({
        icon: "success",
        timer: 1500,
        text: post.message,
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    createPost(postImage);
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    setPostImage({ ...postImage, logo: base64 });
  };
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-sm-12 col-md-12">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Update Company</h4>
              <hr />
              <div className="form-wrapper">
                {Object.keys(validationError).length > 0 && (
                  <div className="row">
                    <div className="col-12">
                      <div className="alert alert-danger">
                        <ul className="mb-0">
                          {Object.entries(validationError).map(
                            ([key, value]) => (
                              <li key={key}>{value}</li>
                            )
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                )}

                <Form
                  onSubmit={handleSubmit}
                  className="container card mb-2 pt-3"
                >
                  <Row>
                    <Col>
                      <Row className="mb-3">
                        <Form.Group as={Col} controlId="Description">
                          <Form.Label>Company Logo</Form.Label>
                          <Form.Control
                            type="file"
                            required
                            label="Image"
                            name="logo"
                            accept=".jpeg, .png, .jpg"
                            onChange={(e) => handleFileUpload(e)}
                            placeholder="Photos"
                          />
                        </Form.Group>
                      </Row>
                    </Col>
                    <Col>
                    <Form.Group controlId="mobile">
                        <Form.Label></Form.Label>
                        <img src={logo} alt="CloudFront image" class="img-thumbnail mt-4"  width={60} height={60} />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Form.Group as={Row} className="mb-3">
                    <Col sm={{ span: 0, offset: 20 }}>
                      <Button variant="secondary" type="submit">
                        <i class="fa fa-save"></i> Update Photos
                      </Button>
                    </Col>
                  </Form.Group>
                </Form>

                <Form
                  onSubmit={updateCompany}
                  className="container card mb-2 pt-3"
                >
                  <Form>
                    <Row>
                      <Col>
                        <Form.Group controlId="company_name">
                          <Form.Label>Company Name</Form.Label>
                          <Form.Control
                            type="text"
                            id="name"
                            value={company_name}
                            onChange={(event) => {
                              setCompany_name(event.target.value);
                            }}
                            required
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="address">
                          <Form.Label>Address</Form.Label>
                          <Form.Control
                            type="text"
                            required
                            value={address}
                            onChange={(event) => {
                              setAddress(event.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group controlId="email">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="text"
                            required
                            value={email}
                            onChange={(event) => {
                              setEmail(event.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="phone">
                          <Form.Label>Phone</Form.Label>
                          <Form.Control
                            type="text"
                            required
                            value={phone}
                            onChange={(event) => {
                              setPhone(event.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group controlId="mobile">
                          <Form.Label>Mobile</Form.Label>
                          <Form.Control
                            type="text"
                            required
                            value={mobile}
                            onChange={(event) => {
                              setMobile(event.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>

                  <Form.Group as={Row} className="mb-3 mt-3">
                    <Col sm={{ span: 2, offset: 20 }}>
                      <Button variant="secondary" type="submit">
                        Update
                      </Button>
                    </Col>
                  </Form.Group>
                </Form>

                {/* <form onSubmit={handleSubmit}>
                                    <input
                                        type="file"
                                        label="Image"
                                        name="logo"
                                        accept=".jpeg, .png, .jpg"
                                        onChange={(e) => handleFileUpload(e)}
                                    />

                                    <button>Submit</button>
                                </form> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
