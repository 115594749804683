import Table from "react-bootstrap/Table";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

import ReactPaginate from "react-paginate";

export default function ManageCompany() {
    const [searchText, setSearchText] = useState("");
    const [number, setNumber] = useState(1); // No of pages
    const [PerPage] = useState(10);
    const [manageCompany, setmanageCompany] = useState([]);


    useEffect(() => {
        fetchCompany();
    }, []);

    const fetchCompany = async () => {
        await axios
            .get(process.env.REACT_APP_API + "/company/user/1")
            .then(({ data }) => {
                setmanageCompany(data);
            });
    };

    const last = number * PerPage;
    const first = last - PerPage;
    const currentPost = manageCompany.slice(first, last);
    const PageCount = Math.ceil(manageCompany.length / PerPage);
    const ChangePage = ({ selected }) => {
        console.log(selected);
        setNumber(selected);
    };

    // exclude column list from filter
    const excludeColumns = ["id", "date", "name", "e_id"];
    // handle change event of search input
    const handleChange = (value) => {
        setSearchText(value);
        filterData(value);
    };
    // filter records by search text
    const filterData = (value) => {
        const lowercasedValue = value.toLowerCase().trim();
        if (lowercasedValue === "") setmanageCompany(manageCompany);
        else {
            const filteredData = manageCompany.filter((item) => {
                return Object.keys(item).some((key) =>
                    excludeColumns.includes(key)
                        ? false
                        : item[key]
                              .toString()
                              .toLowerCase()
                              .includes(lowercasedValue)
                );
            });
            setmanageCompany(filteredData);
        }
    };

    return (
        <div>
            <div className="col-lg-12 bg-white shadow rounded mb-4 border-1 border-secondary">
                <div className="d-flex d-flex justify-content-between mb-2 ">
                    <div className="">
                        <Form className="container card mb-2 mt-2 bg-transparent border-0 m-0">
                            <Row className="">
                                <Form.Group as={Col}>
                                    <div class="row g-3 align-items-center">
                                        <div class="col-auto">
                                            <label
                                                for="search"
                                                class="col-form-label"
                                            >
                                                Search
                                            </label>
                                        </div>
                                        <div class="col-auto">
                                            <input
                                                type="text"
                                                placeholder="search..."
                                                value={searchText}
                                                onChange={(e) =>
                                                    handleChange(e.target.value)
                                                }
                                                id="search"
                                                class="form-control"
                                                aria-describedby="passwordHelpInline"
                                            />
                                        </div>
                                    </div>
                                </Form.Group>
                            </Row>
                        </Form>
                    </div>
                </div>
            </div>

            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Company Name</th>
                        <th>Address</th>
                        <th>Email Address</th>
                        <th>Logo</th>
                        <th>Action</th>
                    </tr>
                </thead>

                <tbody>
                    {currentPost.length > 0 &&
                        currentPost.map((row, key) => (
                            <tr key={key}>
                                <td>{row.company_name}</td>
                                <td>{row.address}</td>
                                <td>{row.email}</td>

                                <td>
                                <img src={row.logo} alt="CloudFront image" class="img-thumbnail"  width={80} height={80} />

                                </td>

                                <td>
                                    <Link
                                        to={`/company/editcompany/${row.ID}`}
                                        className="btn btn-success ms-1 manageBranch"
                                    >
                                           <i class="fas fa-edit"> Edit </i>
                                    </Link>
                                </td>
                            </tr>
                        ))}

                    <div style={{ marginLeft: "400%" }}>
                        {currentPost.length === 0 && (
                            <div class="text-center">
                                <div
                                    class="spinner-border text-center"
                                    role="status"
                                >
                                    <span class="visually-hidden">
                                        Loading...
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                </tbody>
            </Table>
            {/* <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                pageCount={PageCount}
                onPageChange={ChangePage}
                containerClassName={"paginationBttns"}
                activeClassName={"paginationActive"}
                disableInitialCallback={true}
                initialPage={1}
            ></ReactPaginate> */}
        </div>
    );
}
