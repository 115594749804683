import React, { useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Select from 'react-select';
import Table from 'react-bootstrap/Table';

export default function PaySalary() {

  const [data, setData] = useState();
  const { control, register, handleSubmit } = useForm({
    defaultValues: {
      list: [{ earning: '', Amount: '' , Deductions: ''  , Taka: ''}]
    }
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "list"
  });

  const onSave = data => {
    setData({ ...data });
  }


    const options = [
        { value: 'chocolate', label: 'API2007001' },
        { value: 'strawberry', label: 'API2007002' },
        { value: 'vanilla2', label: 'API2007003' },
        { value: 'chocolate2', label: 'RPI20070013' },
        { value: 'strawberry1', label: 'KPI20070026' },
        { value: 'vanilla', label: 'GPI2007003' },
        { value: 'chocolate3', label: 'FPI20070014' },
        { value: 'strawberry4', label: 'CPI2007002' },
        { value: 'vanilla3', label: 'BPI2007003' },
      ]
    return( 
    <div>  
      <Form className="container card">
        <Row className="mb-3">
        <Form.Group as={Col} controlId="gender">
            <Form.Label>Employee ID</Form.Label>
            <Select options={options} />
          </Form.Group>

          <Form.Group as={Col} controlId="Salary">
          <Form.Label>Register Date</Form.Label>
          <Form.Control type="date" placeholder="Register Date" />
          </Form.Group>
        </Row>
        <Table striped bordered hover>
        <thead>
          <tr>
            <th>Earning</th>
            <th>Amount in Taka</th>
            <th>Deductions</th>
            <th>Amount in Taka</th>
          </tr>
        </thead>
        <tbody>
  
        {fields.map((field, index) => (
          <tr>
            <td> <input placeholder="Enter Earning" {...register(`list.${index}.earning`)}/></td>
            <td><input placeholder="Enter Amount" {...register(`list.${index}.Amount`)}/></td>
            <td> <input className="ml10" placeholder="Enter Deductions" {...register(`list.${index}.Deductions`)}/></td>
            <td><input placeholder="Enter Amount" {...register(`list.${index}.Taka`)} /></td>
            <td> {fields.length !== 1 && <button
            className="mr10"
            onClick={() => remove(index)}>Remove</button>}
          {fields.length - 1 === index && <button onClick={() => append({ earning: '', Amount: '', Deductions: '' , Taka: ''})}> 
          <i class="fa fa-plus"></i> Add</button>}
       </td>
          </tr>
          ))}
   
        </tbody>
      
      </Table>
      <Form.Group as={Row} className="mb-3 mt-3">
        <Col sm={{ span: 2, offset: 20 }}>
          <Button variant="secondary" type="submit">Save</Button>
        </Col>
      </Form.Group>
      </Form>
      {data && <pre>{JSON.stringify(data, null, 2)}</pre>}
    </div>
    
    )
}