import React, { useState } from "react"
import AuthUser from './AuthUser';
import {Link } from "react-router-dom";
export default function Login() {
    const {http, setToken} = AuthUser();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();

    const submitForm = () =>{

      // temporary login
      if (email === process.env.REACT_APP_AUTH_EMAIL && password === process.env.REACT_APP_AUTH_PASSWORD) {
        setToken({}, process.env.REACT_APP_TOKEN);
      }

        // api call
       // http.post('/login',{email:email, password:password}).then((res)=>{
           //  setToken(res.data.user,res.data.access_token);
           //  setToken({}, '1werkjf8wer5sddf2wer');
        // })

    }
  return (
<div className="loginForm position-absolute min-vh-100 overflow-hidden d-flex align-items-center w-100 bg-white top-0 start-0" style={{ zIndex: 1200 }}>
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-10 col-xl-7">
        <div className="page-style">
          <div className="row justify-content-center">
            <div className="col-lg-5 d-none d-lg-block">
              <div className="auth-left d-flex algin-items-center justify-content-center flex-column text-center h-100">
                <div className="auth-left-content text-center">
                  <img
                    src="assets/img/login.png"
                    className="img-fluid"
                    alt="img"
                  />
                </div>
                <h5 className="mb-0 mt-3">
                  Welcome to
                  <br />
                  Face Recognition
                </h5>
              </div>
            </div>
            <div className="col-lg-7 h-100">
              <div className="auth-right h-100">
                <div
                  style={{ maxWidth: "380px" }}
                  className="sign-in-form rounded p-5 m-auto"
                >
                  <h1 className="fs-3 mb-5 text-center fw-bold">SIGN IN</h1>
                  <div className="form-group mb-3">
                    <label className="form-label mb-1" htmlFor="email-address">
                      Email or Username
                    </label>
                    <input
                      type="email"
                      className="null form-control border border-dark form-control-lg rounded"
                      placeholder="Enter email"
                      onChange={(e) => setEmail(e.target.value)}
                      id="email"
                    />
                  </div>
                  <div className="form-group mb-1">
                    <label className="form-label mb-1" htmlFor="password">
                      Password
                    </label>
                    <div className="password-group position-relative">
                      <input
                        type="password"
                        className="null form-control border border-dark form-control-lg rounded"
                        placeholder="Enter password"
                        onChange={(e) => setPassword(e.target.value)}
                        id="pwd"
                      />
                    </div>
                  </div>
                  <div className="form-group text-end mb-4">
                    <Link className="text-decoration-none text-dark" href="#">
                      Forgot Password?
                    </Link>
                  </div>
                  <button
                    className="py-2 w-100 btn btn-dark rounded text-light"
                    type="submit"
                    onClick={submitForm}
                  >
                    Sign In
                  </button>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  )
}

