import Table from "react-bootstrap/Table";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { CSVLink } from "react-csv";
import ReactPaginate from "react-paginate";

export default function EmployeeFileManagement() {
  const [searchText, setSearchText] = useState("");
  const [number, setNumber] = useState(1); // No of pages
  const [PerPage] = useState(10);
  const [manageDesignation, setManageDesignation] = useState([]);
  const fileName = "Branch"; // here enter filename for your excel file

  useEffect(() => {
    fetchDesignation();
  }, []);

  const fetchDesignation = async () => {
    await axios
      .get(process.env.REACT_APP_API + "/designation/APILimited")
      .then(({ data }) => {
        setManageDesignation(data);
      });
  };

  const deleteDesignation = async (ID) => {
    const isConfirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      return result.isConfirmed;
    });
    if (!isConfirm) {
      return;
    }
    await axios
      .delete(
        `https://362jmo35e9.execute-api.ca-central-1.amazonaws.com/pod/APILimited/${ID}`
      )
      .then(({ data }) => {
        Swal.fire({
          icon: "success",
          text: data.message,
        });
        fetchDesignation();
      })
      .catch(({ response: { data } }) => {
        Swal.fire({
          text: data.message,
          icon: "error",
        });
      });
  };

  const last = number * PerPage;
  const first = last - PerPage;
  const currentPost = manageDesignation.slice(first, last);
  const PageCount = Math.ceil(manageDesignation.length / PerPage);
  const ChangePage = ({ selected }) => {
    console.log(selected);
    setNumber(selected);
  };

  // exclude column list from filter
  const excludeColumns = ["id", "date", "name", "e_id"];
  // handle change event of search input
  const handleChange = (value) => {
    setSearchText(value);
    filterData(value);
  };
  // filter records by search text
  const filterData = (value) => {
    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue === "") setManageDesignation(manageDesignation);
    else {
      const filteredData = manageDesignation.filter((item) => {
        return Object.keys(item).some((key) =>
          excludeColumns.includes(key)
            ? false
            : item[key].toString().toLowerCase().includes(lowercasedValue)
        );
      });
      setManageDesignation(filteredData);
    }
  };

  return (
    <div>
      <div className="d-flex justify-content-between">
        {/* <div className="col-lg-6 d-flex align-items-center ps-0">
          <Link to={"/addfile"} className="btn btn-success ms-1 manageBranch">
             <i className="fa fa-plus" /> Add file
          </Link>
        </div> */}
        <div className="col-lg-6 pe-0">
          <Form className="container card mb-2 mt-2 bg-transparent border-0 m-0 p-0">
            <Row className="">
              <Form.Group as={Col}>
                <div class="">
                  <input
                    type="text"
                    placeholder="search..."
                    value={searchText}
                    onChange={(e) => handleChange(e.target.value)}
                    id="search"
                    className="form-control"
                    aria-describedby="passwordHelpInline"
                  />
                </div>
              </Form.Group>
            </Row>
          </Form>
        </div>
      </div>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>SL</th>
            <th>Caption</th>
            <th>Created at</th>
            <th>Download</th>
          </tr>
        </thead>

        <tbody>
          {currentPost.length > 0 &&
            currentPost.map((row, key) => (
              <tr key={key}>
                <td>{key + 1}</td>
                <td>
                  Interdum varius commodo quisque a placerat accumsan lorem{" "}
                </td>
                <td>28-4-2023</td>

                <td>
                  <Link to={``} className="btn btn-success ms-1 manageBranch">
                    <i class="fas fa-download"> Download </i>
                  </Link>
                </td>
              </tr>
            ))}

          <div style={{ marginLeft: "380%" }}>
            {currentPost.length === 0 && (
              <div class="text-center">
                <div class="spinner-border text-center" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            )}
          </div>
        </tbody>
      </Table>
      <ReactPaginate
        previousLabel={"Previous"}
        nextLabel={"Next"}
        pageCount={PageCount}
        onPageChange={ChangePage}
        containerClassName={"paginationBttns"}
        activeClassName={"paginationActive"}
        disableInitialCallback={true}
        initialPage={1}
      ></ReactPaginate>
    </div>
  );
}
