import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Select from 'react-select'
export default function Addsalary() {

    const options = [
        { value: 'chocolate', label: 'API2007001' },
        { value: 'strawberry', label: 'API2007002' },
        { value: 'vanilla', label: 'API2007003' }
      ]
      
    return(
      
      <div>
         

    <Form className="container card">
      <Row className="mb-3">
      <Form.Group as={Col} controlId="gender">
          <Form.Label>Employee ID</Form.Label>
          <Select options={options} />
        </Form.Group>

        <Form.Group as={Col} controlId="Salary">
          <Form.Label>Salary</Form.Label>
          <Form.Control type="text" placeholder="Salary" />
        </Form.Group>
      </Row>
    
      <Row className="mb-6">
      <Form.Group as={Col} controlId="registerdate">
          <Form.Label>Register Date</Form.Label>
          <Form.Control type="date" placeholder="Register Date" />
        </Form.Group>
      </Row>

      <Form.Group as={Row} className="mb-3 mt-3">
        <Col sm={{ span: 2, offset: 20 }}>
          <Button variant="secondary" type="submit">Save</Button>
        </Col>
      </Form.Group>
    </Form>

   

      {/* <Row className="mb-3">
        <Form.Group as={Col} controlId="formGridCity">
          <Form.Label>City</Form.Label>
          <Form.Control />
        </Form.Group>

        <Form.Group as={Col} controlId="formGridState">
          <Form.Label>State</Form.Label>
          <Form.Select defaultValue="Choose...">
            <option>Choose...</option>
            <option value="1">Male</option>
            <option value="2">Female</option>
          </Form.Select>
        </Form.Group>
        <Form.Group as={Col} controlId="formGridZip">
          <Form.Label>Zip</Form.Label>
          <Form.Control />
        </Form.Group>
      </Row>

      <Form.Group className="mb-3" id="formGridCheckbox">
        <Form.Check type="checkbox" label="Check me out" />
      </Form.Group> */}

    </div>
    
    )
}