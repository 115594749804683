import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

export default function Department() {
    const navigate = useNavigate();
    const [validationError, setValidationError] = useState({});

    const createDepartment = async (e) => {
        e.preventDefault();

        var Department = document.getElementById("Department").value;
        var Departments_Details = document.getElementById(
            "Departments_Details"
        ).value;

        const postData = {
            Department,
            Departments_Details,
        };

        await axios
            .post(
              process.env.REACT_APP_API + "/departments/APILimited",
                postData,
                {}
            )
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    timer: 1500,
                    showConfirmButton: false,
                    text: data.message,
                });
                navigate("/department");
            })
            .catch(({ response }) => {
                if (response.status === 422) {
                    setValidationError(response.data.errors);
                } else {
                    Swal.fire({
                        text: response.data.message,
                        icon: "error",
                    });
                }
            });
    };

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 col-sm-12 col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Create Department</h4>
                            <hr />
                            <div className="form-wrapper">
                                {Object.keys(validationError).length > 0 && (
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="alert alert-danger">
                                                <ul className="mb-0">
                                                    {Object.entries(
                                                        validationError
                                                    ).map(([key, value]) => (
                                                        <li key={key}>
                                                            {value}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <Form onSubmit={createDepartment}>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="Department">
                                                <Form.Label>
                                                    Departments
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="Department"
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Form.Group controlId="Departments_Details">
                                                <Form.Label>
                                                    Departments Details
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    required
                                                    id="Departments_Details"
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Form.Group as={Row} className="mb-3 mt-3">
                                        <Col sm={{ span: 2, offset: 20 }}>
                                            <Button
                                                variant="secondary"
                                                type="submit"
                                            >
                                                <i class="fa fa-save"></i>  Save
                                            </Button>
                                        </Col>
                                    </Form.Group>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
