import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

export default function Edit() {
    const navigate = useNavigate();

    const { ID } = useParams();
    const [Designation, setDesignation] = useState("");
    const [Designation_Details, setDesignation_Details] = useState("");

    const [validationError, setValidationError] = useState({});

    useEffect(() => {
        fetchBranch();
    }, []);

    const fetchBranch = async () => {
        await axios
            .get(
                `https://hefhvf2eji.execute-api.ca-central-1.amazonaws.com/pod/APILimited/${ID}`
            )
            .then(({ data }) => {
                const { Designation } = data[0];
                const { Designation_Details } = data[0];

                setDesignation(Designation);
                setDesignation_Details(Designation_Details);
            })
            .catch(({ response: { data } }) => {
                Swal.fire({
                    text: data.message,
                    icon: "error",
                });
            });
    };

    const updateDepartment = async (e) => {
        e.preventDefault();

        await axios
            .post(
                `${process.env.REACT_APP_API}/designation/APILimited/${ID}`,
                {
                    Designation: Designation,
                    Designation_Details: Designation_Details,
                }
            )
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    timer: 1500,
                    text: data.message,
                });
                navigate("/managedesignation");
            })
            .catch(({ response }) => {
                if (response.status === 422) {
                    setValidationError(response.data.errors);
                } else {
                    Swal.fire({
                        text: response.data.message,
                        icon: "error",
                    });
                }
            });
    };

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 col-sm-12 col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Update Designation</h4>
                            <hr />
                            <div className="form-wrapper">
                                {Object.keys(validationError).length > 0 && (
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="alert alert-danger">
                                                <ul className="mb-0">
                                                    {Object.entries(
                                                        validationError
                                                    ).map(([key, value]) => (
                                                        <li key={key}>
                                                            {value}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <Form onSubmit={updateDepartment}>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="Name">
                                                <Form.Label>
                                                   Designation
                                                </Form.Label>

                                                <Form.Control
                                                    type="text"
                                                    required
                                                    value={Designation}
                                                    onChange={(event) => {
                                                        setDesignation(
                                                            event.target.value
                                                        );
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="Name">
                                                <Form.Label>
                                                Designation Details
                                                </Form.Label>

                                                <Form.Control
                                                    type="text"
                                                    required
                                                    style={{ height: "100px" }}
                                                    value={Designation_Details}
                                                    onChange={(event) => {
                                                        setDesignation_Details(
                                                            event.target.value
                                                        );
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Form.Group as={Row} className="mb-3 mt-3">
                                        <Col sm={{ span: 2, offset: 20 }}>
                                            <Button
                                                variant="secondary"
                                                type="submit"
                                            >
                                                <i class="fa fa-save"></i>  Update
                                            </Button>
                                        </Col>
                                    </Form.Group>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
