import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

export default function Edit() {
    const navigate = useNavigate();

    const { ID } = useParams();
    const [Country, setCountry] = useState("");
    const [State, setState] = useState("");
    const [Division, setDivision] = useState("");
    const [City, setCity] = useState("");
    const [District, setDistrict] = useState("");
    const [Area, setArea] = useState("");
    const [Address, setAddress] = useState("");
    const [validationError, setValidationError] = useState({});

    useEffect(() => {
        fetchBranch();
    }, []);

    const fetchBranch = async () => {
        await axios
            .get(
              process.env.REACT_APP_API + `/branch/APILimited/${ID}`
            )
            .then(({ data }) => {
                const { Country } = data[0];
                const { State } = data[0];
                const { Division } = data[0];
                const { City } = data[0];
                const { District } = data[0];
                const { Area } = data[0];
                const { Address } = data[0];
                setCountry(Country);
                setState(State);
                setDivision(Division);
                setCity(City);
                setDistrict(District);
                setArea(Area);
                setAddress(Address);

            })
            .catch(({ response: { data } }) => {
                Swal.fire({
                    text: data.message,
                    icon: "error",
                });
            });
    };

    const updateBranch = async (e) => {
        e.preventDefault();

        await axios
            .post(
              process.env.REACT_APP_API + `/branch/APILimited/${ID}`,
                {
                    Country: Country,
                    State: State,
                    Division: Division,
                    City: City,
                    District: District,
                    Area: Area,
                    Address: Address,


                }
            )
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    timer: 1500,
                    text: data.message,
                });
                navigate("/managebranch");
            })
            .catch(({ response }) => {
                if (response.status === 422) {
                    setValidationError(response.data.errors);
                } else {
                    Swal.fire({
                        text: response.data.message,
                        icon: "error",
                    });
                }
            });
    };

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 col-sm-12 col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Update Branch</h4>
                            <hr />
                            <div className="form-wrapper">
                                {Object.keys(validationError).length > 0 && (
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="alert alert-danger">
                                                <ul className="mb-0">
                                                    {Object.entries(
                                                        validationError
                                                    ).map(([key, value]) => (
                                                        <li key={key}>
                                                            {value}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <Form onSubmit={updateBranch}>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="Name">
                                                <Form.Label>Country</Form.Label>

                                                <Form.Control
                                                    type="text"
                                                    value={Country}
                                                    onChange={(event) => {
                                                        setCountry(
                                                            event.target.value
                                                        );
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="Name">
                                                <Form.Label>State</Form.Label>

                                                <Form.Control
                                                    type="text"
                                                    value={State}
                                                    onChange={(event) => {
                                                        setState(
                                                            event.target.value
                                                        );
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="Name">
                                                <Form.Label>Division</Form.Label>

                                                <Form.Control
                                                    type="text"
                                                    value={Division}
                                                    onChange={(event) => {
                                                        setDivision(
                                                            event.target.value
                                                        );
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="Name">
                                                <Form.Label>City</Form.Label>

                                                <Form.Control
                                                    type="text"
                                                    value={City}
                                                    onChange={(event) => {
                                                        setCity(
                                                            event.target.value
                                                        );
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="Name">
                                                <Form.Label>District</Form.Label>

                                                <Form.Control
                                                    type="text"
                                                    value={District}
                                                    onChange={(event) => {
                                                        setDistrict(
                                                            event.target.value
                                                        );
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="Name">
                                                <Form.Label>Area</Form.Label>

                                                <Form.Control
                                                    type="text"
                                                    value={Area}
                                                    onChange={(event) => {
                                                        setArea(
                                                            event.target.value
                                                        );
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Form.Group controlId="Name">
                                                <Form.Label>Address</Form.Label>

                                                <Form.Control
                                                    type="text"
                                                    value={Address}
                                                    onChange={(event) => {
                                                        setAddress(
                                                            event.target.value
                                                        );
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Form.Group as={Row} className="mb-3 mt-3">
                                        <Col sm={{ span: 2, offset: 20 }}>
                                            <Button
                                                variant="secondary"
                                                type="submit"
                                            >
                                                <i class="fa fa-save"></i>  Update
                                            </Button>
                                        </Col>
                                    </Form.Group>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
