import React from 'react';
import Table from 'react-bootstrap/Table';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Select from 'react-select';
import Button from 'react-bootstrap/Button';
export default function Employeelog() {

    const options = [
        { value: 'chocolate', label: 'API2007001' },
        { value: 'strawberry', label: 'API2007002' },
        { value: 'vanilla2', label: 'API2007003' },
        { value: 'chocolate2', label: 'RPI20070013' },
        { value: 'strawberry1', label: 'KPI20070026' },
        { value: 'vanilla', label: 'GPI2007003' },
        { value: 'chocolate3', label: 'FPI20070014' },
        { value: 'strawberry4', label: 'CPI2007002' },
        { value: 'vanilla3', label: 'BPI2007003' },
      ] 
    return(
        <div>
    <Form className="container card mb-2 pt-3">     
    <Row className="mb-4 ">
        <Form.Group as={Col} controlId="gender">
            <Form.Label>Employee ID</Form.Label>
            <Select options={options} />
          </Form.Group>

          <Form.Group as={Col} controlId="Salary">
          <Form.Label>From Date</Form.Label>
          <Form.Control type="date" placeholder="Register Date" />
          </Form.Group>
          <Form.Group as={Col} controlId="Salary">
          <Form.Label>To Date</Form.Label>
          <Form.Control type="date" placeholder="Register Date" />
          </Form.Group>
          <Form.Group as={Row} className="mt-4">
        <Col sm={{ span: 2, offset: 20 }}>
          <Button variant="secondary" type="submit">Save</Button>
        </Col>
      </Form.Group>
        </Row>
    </Form>  
            <Table striped bordered hover>
                <thead>
                    <tr>
                    <th>SL</th>
                    <th>First Name</th>
                    <th>Employee ID</th>
                    <th>InTime</th>
                    <th>OutTime</th>
                    <th>Date</th>
                    <th>Action</th>
                    </tr>
                </thead>
                    
                <tbody>
                    <tr>
                    <td>1</td>
                    <td>Md.Mizanur Rahaman</td>
                    <td>API2007001</td>
                    <td>11:28:32 AM</td>
                    <td>00:00:00 PM</td>
                    <td>2022-1-5 </td>
                    <td><button type="button" className="btn btn-success">Edit</button>  <button type="button" className="btn btn-danger">Delete</button></td>               
                    </tr>
                    <tr>
                    <td>1</td>
                    <td>Md.Mizanur Rahaman</td>
                    <td>API2007001</td>
                    <td>11:28:32 AM</td>
                    <td>00:00:00 PM</td>
                    <td>2022-1-5 </td>
                    <td><button type="button" className="btn btn-success">Edit</button>  <button type="button" className="btn btn-danger">Delete</button></td>               
                    </tr>
                    <tr>
                    <td>1</td>
                    <td>Md.Mizanur Rahaman</td>
                    <td>API2007001</td>
                    <td>11:28:32 AM</td>
                    <td>00:00:00 PM</td>
                    <td>2022-1-5 </td>
                    <td><button type="button" className="btn btn-success">Edit</button>  <button type="button" className="btn btn-danger">Delete</button></td>               
                    </tr>
                    <tr>
                    <td>1</td>
                    <td>Md.Mizanur Rahaman</td>
                    <td>API2007001</td>
                    <td>11:28:32 AM</td>
                    <td>00:00:00 PM</td>
                    <td>2022-1-5 </td>
                    <td><button type="button" className="btn btn-success">Edit</button>  <button type="button" className="btn btn-danger">Delete</button></td>               
                    </tr>
                    <tr>
                    <td>1</td>
                    <td>Md.Mizanur Rahaman</td>
                    <td>API2007001</td>
                    <td>11:28:32 AM</td>
                    <td>00:00:00 PM</td>
                    <td>2022-1-5 </td>
                    <td><button type="button" className="btn btn-success">Edit</button>  <button type="button" className="btn btn-danger">Delete</button></td>               
                    </tr>
                    <tr>
                    <td>1</td>
                    <td>Md.Mizanur Rahaman</td>
                    <td>API2007001</td>
                    <td>11:28:32 AM</td>
                    <td>00:00:00 PM</td>
                    <td>2022-1-5 </td>
                    <td><button type="button" className="btn btn-success">Edit</button>  <button type="button" className="btn btn-danger">Delete</button></td>               
                    </tr>
                    <tr>
                    <td>1</td>
                    <td>Md.Mizanur Rahaman</td>
                    <td>API2007001</td>
                    <td>11:28:32 AM</td>
                    <td>00:00:00 PM</td>
                    <td>2022-1-5 </td>
                    <td><button type="button" className="btn btn-success">Edit</button>  <button type="button" className="btn btn-danger">Delete</button></td>               
                    </tr>
                    <tr>
                    <td>1</td>
                    <td>Md.Mizanur Rahaman</td>
                    <td>API2007001</td>
                    <td>11:28:32 AM</td>
                    <td>00:00:00 PM</td>
                    <td>2022-1-5 </td>
                    <td><button type="button" className="btn btn-success">Edit</button>  <button type="button" className="btn btn-danger">Delete</button></td>               
                    </tr>
                    <tr>
                    <td>1</td>
                    <td>Md.Mizanur Rahaman</td>
                    <td>API2007001</td>
                    <td>11:28:32 AM</td>
                    <td>00:00:00 PM</td>
                    <td>2022-1-5 </td>
                    <td><button type="button" className="btn btn-success">Edit</button>  <button type="button" className="btn btn-danger">Delete</button></td>               
                    </tr>
                   
                </tbody>
                </Table>
                </div>
        );
}