import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from '../components/login';
function Guest() {
    return (
        <>        
            <div className="container">
                <Routes>
                    <Route path="/" element={<Login />} />
                </Routes>
            </div>
        </>
    );
}
export default Guest;
