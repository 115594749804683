import React, { useEffect, useState } from "react";
import axios from "axios";
import {
    Document,
    Page,
    Text,
    View,
    Image,
    StyleSheet,
} from "@react-pdf/renderer";
import logo from "./images/logo.png";

const styles = StyleSheet.create({
    table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderColor: "#aaaaaa",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row",
    },
    tableHead: {
        margin: "auto",
        flexDirection: "row",
        backgroundColor: "#E5E5CB",
        height: "20px",
    },
    tableText: {
        margin: "auto",
        color: "#222222",
        fontSize: "10px",
        fontWeight: "700",
    },
    tableCol: {
        width: "105px",
        borderStyle: "solid",
        borderColor: "#aaaaaa",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    serial: {
        width: "40px",
        borderStyle: "solid",
        borderColor: "#aaaaaa",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableCell: {
        margin: "auto",
        marginTop: 3,
        marginBottom: 3,
        fontSize: 8,
    },
    row: {
        flexDirection: "row",
        borderBottomColor: "#3778C2",
        borderBottomWidth: 1,
        alignItems: "center",
        height: 15,
        fontSize: 10,
        fontStyle: "bold",
        backgroundColor: "#aaaaaa",
    },
    titleContainer: {
        marginTop: 12,
    },
    reportTitle: {
        fontSize: 12,
        textAlign: "center",
        textTransform: "uppercase",
    },
    header: {
        marginTop: 15,
        marginBottom: 10,
    },
    APItitle: {
        color: "#035481",
        fontSize: 16,
        marginBottom: "5px",
        textAlign: "center",
        textTransform: "uppercase",
    },
    addressPhone: {
        color: "#222222",
        fontSize: 9,
        textAlign: "center",
        marginBottom: "2px",
    },
    jiaur: {
        paddingRight: "34px",
        paddingLeft: "30px",
    },
    logo: {
        width: 65,
        height: "auto",
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: "5px",
    },
});

function MyDocument() {
    const [manageMonthlyEarning, setManageMonthlyEarning] = useState([]);
    useEffect(() => {
        fetchMonthlyEarning();
    }, []);
    const fetchMonthlyEarning = async () => {
        await axios
            .get(process.env.REACT_APP_API + "/monthly_earning/APILimited")
            .then(({ data }) => {
                setManageMonthlyEarning(data);
            });
    };
    const [manageCompany, setmanageCompany] = useState([]);

    useEffect(() => {
        fetchCompany();
    }, []);

    const fetchCompany = async () => {
        await axios
            .get(process.env.REACT_APP_API + "/company/user/1")
            .then(({ data }) => {
                setmanageCompany(data);
            });
    };
    return (
        <Document>
            <Page style={styles.body}>
                <View style={styles.jiaur}>
                    {manageCompany.length > 0 &&
                        manageCompany.map((row, key) => (
                            <View style={styles.header} key={key}>
                                <Image style={styles.logo} src={row.logo} />
                                <Text style={styles.APItitle}>
                                    {row.company_name}
                                </Text>
                                <Text style={styles.addressPhone}>
                                    Address: {row.address}
                                </Text>
                                <Text style={styles.addressPhone}>
                                    Phone: {row.phone}, E-mail:
                                    {row.email}
                                </Text>
                            </View>
                        ))}

                    <View style={styles.table}>
                        <View style={styles.tableHead}>
                            {/* <View style={styles.serial}>
                                <Text style={styles.tableText}>SL</Text>
                            </View> */}
                            <View style={styles.serial}>
                                <Text style={styles.tableText}>E-ID</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableText}>Type</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableText}>Entity</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableText}>Amount</Text>
                            </View>

                        </View>

                        {manageMonthlyEarning.length > 0 &&
                            manageMonthlyEarning.map((row, key) => (
                                <View style={styles.tableRow} key={key}>
                                    {/* <View style={styles.serial}>
                                        <Text style={styles.tableCell}>
                                            {key + 1}
                                        </Text>
                                    </View> */}
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>
                                            {row.E_ID}
                                        </Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>
                                            {row.Type == 1
                                                ? "Earning"
                                                : "Deduction"}
                                        </Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>
                                            {row.Entity}
                                        </Text>
                                    </View>

                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>
                                            {row.Amount}
                                        </Text>
                                    </View>
                                </View>
                            ))}
                    </View>

                    <View style={styles.titleContainer}>
                        <Text style={styles.reportTitle}>
                            *** Thank You ***
                        </Text>
                    </View>
                </View>
            </Page>
        </Document>
    );
}
export default MyDocument;
