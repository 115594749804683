import React, { useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import Select from "react-select";
export default function SalarySetting() {
    const [data, setData] = useState();
    const { control, register, handleSubmit } = useForm({
        defaultValues: {
            list: [{ earning: "", Amount: "", Deductions: "", Taka: "" }],
        },
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: "list",
    });

    const onSave = (data) => {
        setData({ ...data });
    };

    const options = [
        { value: "January", label: "January" },
        { value: "February", label: "February" },
        { value: "March", label: "March" },
        { value: "April", label: "April" },
        { value: "May", label: "May" },
        { value: "June", label: "June" },
        { value: "July", label: "July" },
        { value: "August", label: "August" },
        { value: "September", label: "September" },
        { value: "October", label: "October" },
        { value: "November", label: "November" },
        { value: "December", label: "December" },
    ];

    return (
        <div>
            <Form className="container card">
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Entity Selection</th>
                            <th>Select Entry</th>
                            <th>Fixed /Parsentance</th>
                            <th>Amount</th>
                            <th>Applicable</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fields.map((field, index) => (
                            <tr>
                                <td>
                                    <select
                                        aria-label="Default select example"
                                        class="form-select tardinesscons"
                                        id="Gender"
                                    >
                                        <option value="">Earning</option>
                                        <option value="">Deduction</option>
                                    </select>
                                </td>
                                <td>
                                    <select
                                        aria-label="Default select example"
                                        class="form-select tardinesscons"
                                        id="Gender"
                                    >
                                        <option value="">Food Cost</option>
                                        <option value="">
                                            Transprotation Cost
                                        </option>
                                        <option value="">Entertatmant</option>
                                        <option value="">
                                            Medical Allowance
                                        </option>
                                        <option value="">House rent</option>
                                    </select>
                                </td>
                                <td>
                                    <select
                                        aria-label="Default select example"
                                        class="form-select tardinesscons"
                                        id="Gender"
                                    >
                                        <option value="">Fixed</option>
                                        <option value="">Percentage</option>
                                    </select>
                                </td>
                                <td>
                                    <input placeholder="Enter Amount" />
                                </td>

                                <td>
                                    <Select isMulti options={options} />
                                </td>

                                <td>
                                    {fields.length !== 1 && (
                                        <button
                                            className="mr10 text-danger"
                                            onClick={() => remove(index)}
                                        >
                                            <i
                                                class="fa fa-minus-circle"
                                                aria-hidden="true"
                                            ></i>
                                        </button>
                                    )}
                                    {fields.length - 1 === index && (
                                        <button
                                            class="text-success"
                                            onClick={() =>
                                                append({
                                                    earning: "",
                                                    Amount: "",
                                                    Deductions: "",
                                                    Taka: "",
                                                })
                                            }
                                        >
                                            <i class="fa fa-plus-circle"></i>
                                        </button>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <Form.Group as={Row} className="mb-3 mt-3">
                    <Col sm={{ span: 2, offset: 20 }}>
                        <Button variant="secondary" type="submit">
                            Save
                        </Button>
                    </Col>
                </Form.Group>
            </Form>
            {data && <pre>{JSON.stringify(data, null, 2)}</pre>}
        </div>
    );
}
