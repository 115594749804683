import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import env from "react-dotenv";

function AddTardiness() {

console.log(env.REACT_APP_API);

    const navigate = useNavigate();
    const [validationError, setValidationError] = useState({});
    const [manageemployee, setManageEmployee] = useState("");

    useEffect(() => {
        fetchManageEmployee();
    }, []);

    const fetchManageEmployee = async () => {
        await axios
            .get(process.env.REACT_APP_API + "/employee/APILimited")
            .then(({ data }) => {
                setManageEmployee(data);
            });
    };

    const [inputList, setInputList] = useState([
        { E_ID: "", tardiness_consideration: "", reason: "", updated_on: "" },
    ]);

    // handle input change
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    };

    // handle click event of the Remove button
    const handleRemoveClick = (index) => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        setInputList([
            ...inputList,
            {
                E_ID: "",
                tardiness_consideration: "",
                reason: "",
                updated_on: "",
            },
        ]);
    };

    const handleButtonClick = (e) => {
        e.preventDefault();

        let isError = false;

        inputList.forEach((item) => {
            if(!item.E_ID || !item.tardiness_consideration || !item.reason || !item.updated_on){
                isError = true;

               return Swal.fire({
                    icon: "warning",
                    timer: 1500,
                    text: "Error occurred",
                });
            }
        });

        if(isError == false){
            let counter = 0;

            inputList.forEach((item) => {
                    axios
                        .post(
                          process.env.REACT_APP_API + "/tardiness_consideration/APILimited",
                            item
                        )

                    counter++;
                    if(counter === inputList.length){
                        Swal.fire({
                            icon: "success",
                            timer: 1500,
                            text: "Success message",
                        });

                        setInputList([{
                            E_ID: "",
                            tardiness_consideration: "",
                            reason: "",
                            updated_on: "",
                        }]);
                    }
            });
        }
    };
    return (
        <div>
            <Form className="container card pt-3">
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Employee ID</th>
                            <th>Minutes</th>
                            <th>Reason</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {inputList.map((x, i) => {
                            return (
                                <tr>
                                    <td>
                                        <Form.Group>
                                            <Form.Select
                                                class="form-select tardinesscons"
                                                aria-label="Default select example"
                                                name="E_ID"
                                                placeholder="Enter E_ID"
                                                value={x.E_ID}
                                                onChange={(e) =>
                                                    handleInputChange(e, i)
                                                }
                                            >
                                                <option>
                                                    Select an option
                                                </option>
                                                {manageemployee.length > 0 &&
                                                    manageemployee.map(
                                                        (row) => (
                                                            <option
                                                                value={row.id}
                                                                key={row.id}
                                                            >
                                                                {
                                                                    row.Employee_ID
                                                                }
                                                                -
                                                                {
                                                                    row.employee_name
                                                                }
                                                            </option>
                                                        )
                                                    )}
                                            </Form.Select>
                                        </Form.Group>
                                    </td>

                                    <td>
                                        <input
                                            class="tardinesscons"
                                            name="tardiness_consideration"
                                            placeholder="Tardiness Consideration"
                                            value={x.tardiness_consideration}
                                            onChange={(e) =>
                                                handleInputChange(e, i)
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input
                                            class="tardinesscons"
                                            name="reason"
                                            placeholder="Reason"
                                            value={x.reason}
                                            onChange={(e) =>
                                                handleInputChange(e, i)
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input
                                            class="tardinesscons"
                                            name="updated_on"
                                            type="date"
                                            placeholder="Enter date"
                                            value={x.updated_on}
                                            onChange={(e) =>
                                                handleInputChange(e, i)
                                            }
                                        />
                                    </td>

                                    <td>
                                        {inputList.length !== 1 && (
                                            <button
                                                className="mr10 text-danger"
                                                onClick={() =>
                                                    handleRemoveClick(i)
                                                }
                                            >
                                                <i
                                                    class="fa fa-minus-circle"
                                                    aria-hidden="true"
                                                ></i>
                                            </button>
                                        )}
                                        {inputList.length - 1 === i && (
                                            <button
                                                class="text-success"
                                                onClick={handleAddClick}
                                            >
                                                <i class="fa fa-plus-circle"></i>
                                            </button>
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>

                <Form.Group as={Row} className="mb-3 mt-3">
                    <Col sm={{ span: 2, offset: 20 }}>
                        <Button
                            variant="secondary"
                            type="submit"
                            class="tardinesscons"
                            onClick={(e) => handleButtonClick(e)}
                        >
                           <i class="fa fa-save"></i> Save
                        </Button>
                    </Col>
                </Form.Group>
            </Form>
            {/* <div style={{ marginTop: 20 }}>{JSON.stringify(inputList)}</div> */}
        </div>
    );
}
export default AddTardiness;
