import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Table from "react-bootstrap/Table";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import env from "react-dotenv";

export default function HrPolicy() {
    const navigate = useNavigate();
    const [validationError, setValidationError] = useState({});

    const [inputList, setInputList] = useState([
        { E_ID: "", tardiness_consideration: "", reason: "", updated_on: "" },
    ]);

    // handle input change
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    };

    // handle click event of the Remove button
    const handleRemoveClick = (index) => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        setInputList([
            ...inputList,
            {
                E_ID: "",
                tardiness_consideration: "",
                reason: "",
                updated_on: "",
            },
        ]);
    };

    const handleButtonClick = (e) => {
        e.preventDefault();

        let isError = false;

        inputList.forEach((item) => {
            if (
                !item.E_ID ||
                !item.tardiness_consideration ||
                !item.reason ||
                !item.updated_on
            ) {
                isError = true;

                return Swal.fire({
                    icon: "warning",
                    text: "Error occurred",
                });
            }
        });

        if (isError == false) {
            let counter = 0;

            inputList.forEach((item) => {
                axios.post(
                  process.env.REACT_APP_API + "/tardiness_consideration/APILimited",
                    item
                );

                counter++;
                if (counter === inputList.length) {
                    Swal.fire({
                        icon: "success",
                        timer: 1500,
                        text: "Success message",
                    });

                    setInputList([
                        {
                            E_ID: "",
                            tardiness_consideration: "",
                            reason: "",
                            updated_on: "",
                        },
                    ]);
                }
            });
        }
    };
    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 col-sm-12 col-md-12 card">
                    <h3 className="card-title pt-3 ">Salary Deduction HR Policy</h3>
                    <div className="card mt-4">
                        <div className="card-body">
                            <h4 className="card-title">
                                Allocated Tardiness (Minutes)
                            </h4>
                            <hr />
                            <div className="form-wrapper">
                                {Object.keys(validationError).length > 0 && (
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="alert alert-danger">
                                                <ul className="mb-0">
                                                    {Object.entries(
                                                        validationError
                                                    ).map(([key, value]) => (
                                                        <li key={key}>
                                                            {value}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <Form>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="minutes">
                                                <Form.Label>Minutes</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="minutes"
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Form.Group as={Row} className="mb-3 mt-3">
                                        <Col sm={{ span: 2, offset: 20 }}>
                                            <Button
                                                variant="secondary"
                                                type="submit"
                                            >
                                                <i class="fa fa-save"></i> Save
                                            </Button>
                                        </Col>
                                    </Form.Group>
                                </Form>
                            </div>
                        </div>
                    </div>

                    {/* <div className="card mt-4">
                        <div className="card-body">
                            <h4 className="card-title">
                                Deduction Salary policy
                            </h4>
                            <hr />
                            <div className="form-wrapper">
                                {Object.keys(validationError).length > 0 && (
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="alert alert-danger">
                                                <ul className="mb-0">
                                                    {Object.entries(
                                                        validationError
                                                    ).map(([key, value]) => (
                                                        <li key={key}>
                                                            {value}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <Form>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="minutes">
                                                <Form.Label>
                                                    Deduction Salary
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="minutes"
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Form.Group as={Row} className="mb-3 mt-3">
                                        <Col sm={{ span: 2, offset: 20 }}>
                                            <Button
                                                variant="secondary"
                                                type="submit"
                                            >
                                                <i class="fa fa-save"></i> Save
                                            </Button>
                                        </Col>
                                    </Form.Group>
                                </Form>
                            </div>
                        </div>
                    </div> */}

                    <div className="card mt-4">
                        <div className="card-body">
                            <h4 className="card-title">
                                {/* Consistent late for 1 day salary deduction */}
                                Salary Deduction for Consistent Late
                            </h4>

                            <hr />

                            <Form className="container">
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>For the day(s) of late </th>{" "}
                                            <th>Consistent Late day(s)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {inputList.map((x, i) => {
                                            return (
                                                <tr>
                                                    <td>
                                                        <input
                                                            class="tardinesscons deductionsalary"
                                                            name="tardiness_consideration"
                                                            placeholder="Enter days  "
                                                            value={
                                                                x.tardiness_consideration
                                                            }
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    e,
                                                                    i
                                                                )
                                                            }
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            class="tardinesscons deductionsalary"
                                                            name="tardiness_consideration"
                                                            placeholder="Consistent Late day(s)"
                                                            // value={
                                                            //     x.tardiness_consideration
                                                            // }
                                                            // onChange={(e) =>
                                                            //     handleInputChange(
                                                            //         e,
                                                            //         i
                                                            //     )
                                                            // }
                                                        />
                                                    </td>

                                                    <td>
                                                        {inputList.length !==
                                                            1 && (
                                                            <button
                                                                className="mr10 text-danger"
                                                                onClick={() =>
                                                                    handleRemoveClick(
                                                                        i
                                                                    )
                                                                }
                                                            >
                                                                <i
                                                                    class="fa fa-minus-circle"
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </button>
                                                        )}
                                                        {inputList.length -
                                                            1 ===
                                                            i && (
                                                            <button
                                                                class="text-success"
                                                                onClick={
                                                                    handleAddClick
                                                                }
                                                            >
                                                                <i class="fa fa-plus-circle"></i>
                                                            </button>
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>

                                <Form.Group as={Row} className="mb-3 mt-3">
                                    <Col sm={{ span: 2, offset: 20 }}>
                                        <Button
                                            variant="secondary"
                                            type="submit"
                                            class="tardinesscons"
                                            onClick={(e) =>
                                                handleButtonClick(e)
                                            }
                                        >
                                            <i class="fa fa-save"></i> Save
                                        </Button>
                                    </Col>
                                </Form.Group>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
