import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Document, Page, Text, View, Image, StyleSheet  } from '@react-pdf/renderer';
import logo from "./images/logo.png";


const styles = StyleSheet.create({
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderColor: "#aaaaaa",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row"
  },
  tableHead: {
    margin: "auto",
    flexDirection: "row",
    backgroundColor: "#E5E5CB",
    height: "20px",
  },
  tableText: {
    margin: "auto",
    color: "#222222",
    fontSize: "10px",
    fontWeight: "700",
  },
  tableCol: {
    width: "100px",
    borderStyle: "solid",
    borderColor: "#aaaaaa",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  serial: {
    width: "30px",
    borderStyle: "solid",
    borderColor: "#aaaaaa",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCell: {
    margin: "auto",
    marginTop: 3,
    marginBottom: 3,
    fontSize: 8,
  },
  row: {
    flexDirection: 'row',
    borderBottomColor: '#3778C2',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 15,
    fontSize: 10,
    fontStyle: 'bold',
    backgroundColor: "#aaaaaa",
},
titleContainer: {
  marginTop: 12
},
reportTitle: {
  fontSize: 12,
  textAlign: 'center',
  textTransform: 'uppercase',
},
header: {
  marginTop: 15,
  marginBottom: 10,
},
APItitle: {
  color: '#035481',
  fontSize: 16,
  marginBottom: "5px",
  textAlign: 'center',
  textTransform: 'uppercase',
},
addressPhone: {
  color: '#222222',
  fontSize: 9,
  textAlign: 'center',
  marginBottom: "2px",
},
jiaur:{
  paddingRight:"34px",
  paddingLeft:"30px",
},
logo: {
  width: 65,
  height: 'auto',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: "5px",
},



});


function BasicDocument({permanentlog}) {

  return (


    <Document>
    <Page style={styles.body}>

    <View style={styles.jiaur}>

       <View style={styles.header}>
          <Image style={styles.logo} src={logo} />
          <Text style={styles.APItitle}>API Solutions Limited</Text>
          <Text style={styles.addressPhone}>Address: House -4 Road 23/A Block B Banani Dhaka 1213 Bangladesh </Text>
          <Text style={styles.addressPhone}>Phone: +88-02 55035911, E-mail: hello@apisolutionsltd.com</Text>
        </View>


      <View style={styles.table}>

        <View style={styles.tableHead}>
          <View style={styles.serial}>
            <Text style={styles.tableText}>SL</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableText}>First Name</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableText}>Employee ID</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableText}>InTime</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableText}>OutTime</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableText}>Date</Text>
          </View>
        </View>

        {
                permanentlog.length > 0 && (
                    permanentlog.map((row, key)=>(
                      <View style={styles.tableRow} key={key}>
                      <View style={styles.serial}>
                        <Text style={styles.tableCell}>{row.ID}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{row.Name}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{row.E_ID}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{row.InTime} AM</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{row.OutTime}PM</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{row.Date}</Text>
                      </View>
                    </View>

                    ))
                )
            }





      </View>


        <View style={styles.titleContainer}>
            <Text style={styles.reportTitle}>*** Thank You ***</Text>
        </View>
    </View>

    </Page>
  </Document>
  );
}
export default BasicDocument;
