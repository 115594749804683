import React from 'react';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
export default function CmSalaryReport() {
    return(
        <div>
            <Link to={"/"}  className="btn btn-success manageBranch"><span>Generate for this month </span></Link>
            <Table striped bordered hover>
                <thead>
                    <tr>
                    <th>SL</th>
                    <th>First Name</th>
                    <th>Employee ID</th>
                    <th>Date</th>
                    <th>Action</th>
                    </tr>
                </thead>
                    
                <tbody>
                    <tr>
                    <td>1</td>
                    <td>Md.Mizanur Rahaman</td>
                    <td>API2007001</td>
                    <td>2022-1-5 </td>  
                    <td> <Link to={"/payslip"}  className="btn btn-success manageBranch"><span>Pay Slip</span></Link>
                    </td> 
                    </tr>
                    <tr>
                    <td>1</td>
                    <td>Md.Mizanur Rahaman</td>
                    <td>API2007001</td>
                    <td>2022-1-5 </td>  
                    <td> <Link to={"/payslip"}  className="btn btn-success manageBranch"><span>Pay Slip</span></Link>
                    </td> 
                    </tr>
                    <tr>
                    <td>1</td>
                    <td>Md.Mizanur Rahaman</td>
                    <td>API2007001</td>
                    <td>2022-1-5 </td>  
                    <td> <Link to={"/payslip"}  className="btn btn-success manageBranch"><span>Pay Slip</span></Link>
                    </td> 
                    </tr>
                    <tr>
                    <td>1</td>
                    <td>Md.Mizanur Rahaman</td>
                    <td>API2007001</td>
                    <td>2022-1-5 </td>  
                    <td> <Link to={"/payslip"}  className="btn btn-success manageBranch"><span>Pay Slip</span></Link>
                    </td> 
                    </tr>
                    <tr>
                    <td>1</td>
                    <td>Md.Mizanur Rahaman</td>
                    <td>API2007001</td>
                    <td>2022-1-5 </td>  
                    <td> <Link to={"/payslip"}  className="btn btn-success manageBranch"><span>Pay Slip</span></Link>
                    </td> 
                    </tr>
                    <tr>
                    <td>1</td>
                    <td>Md.Mizanur Rahaman</td>
                    <td>API2007001</td>
                    <td>2022-1-5 </td>  
                    <td> <Link to={"/payslip"}  className="btn btn-success manageBranch"><span>Pay Slip</span></Link>
                    </td> 
                    </tr>
                    <tr>
                    <td>1</td>
                    <td>Md.Mizanur Rahaman</td>
                    <td>API2007001</td>
                    <td>2022-1-5 </td>  
                    <td> <Link to={"/payslip"}  className="btn btn-success manageBranch"><span>Pay Slip</span></Link>
                    </td> 
                    </tr>
                    <tr>
                    <td>1</td>
                    <td>Md.Mizanur Rahaman</td>
                    <td>API2007001</td>
                    <td>2022-1-5 </td>  
                    <td> <Link to={"/payslip"}  className="btn btn-success manageBranch"><span>Pay Slip</span></Link>
                    </td> 
                    </tr>
                    <tr>
                    <td>1</td>
                    <td>Md.Mizanur Rahaman</td>
                    <td>API2007001</td>
                    <td>2022-1-5 </td>  
                    <td> <Link to={"/payslip"}  className="btn btn-success manageBranch"><span>Pay Slip</span></Link>
                    </td> 
                    </tr>
                </tbody>
                </Table>
                </div>
        );
}