import React, { useEffect, useState } from "react";
import axios from "axios";
import {
    Document,
    Page,
    Text,
    View,
    Image,
    StyleSheet,
} from "@react-pdf/renderer";

import logo from "./images/logo.png";

var converter = require("number-to-words");

const styles = StyleSheet.create({
    table: {
        display: "table",
        Width: "auto",
        borderStyle: "solid",
        borderColor: "#aaaaaa",
        // borderWidth: 1,
        // borderRightWidth: 0,
        // borderBottomWidth: 0,
        marginLeft: 20,
        marginRight: 30,
        // paddingRight: 40,
    },
    tableRow: {
        margin: "left",
        flexDirection: "row",
        borderColor: "#aaa",
        borderLeftWidth: 1,
    },
    tableHead: {
        margin: "left",
        flexDirection: "row",
        backgroundColor: "#E5E5CB",
        height: "25px",
        borderColor: "#aaa",
        borderTopWidth: 1,
        borderLeftWidth: 1,
    },
    tableText: {
        margin: "auto",
        color: "#222222",
        fontSize: "10px",
        fontWeight: "700",
    },
    tableCol: {
        width: "25%",
        borderStyle: "solid",
        borderColor: "#aaaaaa",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },

    tableCell: {
        margin: "auto",
        marginTop: 3,
        marginBottom: 3,
        fontSize: 8,
    },

    titleContainer: {
        marginTop: 12,
    },
    reportTitle: {
        fontSize: 12,
        textAlign: "center",
        textTransform: "uppercase",
    },
    header: {
        marginTop: 5,
        marginBottom: 5,
    },
    APItitle: {
        color: "#333",
        fontSize: 12,
        marginBottom: "5px",
        textAlign: "center",
        textTransform: "uppercase",
    },
    addressPhone: {
        color: "#222222",
        fontSize: 9,
        textAlign: "center",
        marginBottom: "2px",
    },
    jiaur: {
        paddingRight: "34px",
        paddingLeft: "30px",
    },
    logo: {
        width: 65,
        height: "auto",
        marginLeft: "auto",
        marginTop: 10,
        marginRight: "auto",
        marginBottom: "5px",
    },

    employeeInfoBox: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginLeft: "20px",
        marginRight: "30px",
        marginBottom: "10px",
        marginTop: "3px",
        borderTop: "1px solid #aaa",
        paddingTop: 5,
    },
    salarySlip: {
        fontSize: 10,
        marginBottom: 3,
    },

    employee: {
        fontSize: 8,
        marginTop: 2,
    },

    row: {
        flexDirection: "row",
        backgroundColor: "#fff",
        borderBottomWidth: 1,
        borderBottomColor: "#ccc",
    },
    cell: {
        paddingVertical: 10,
        paddingHorizontal: 5,
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    idCell: {
        flex: 0.2,
    },
    nameCell: {
        flex: 0.3,
    },
    ageCell: {
        flex: 0.2,
    },
    emailCell: {
        flex: 0.3,
    },
    headerCell: {
        fontWeight: "bold",
    },
    signature: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginLeft: "20px",
        marginRight: "30px",
        marginBottom: "30px",
        marginTop: "20px",
        paddingTop: 15,
    },
    totalTk: {
        flexDirection: "row",
        backgroundColor: "#eee",
        height: "20px",
        borderColor: "#aaa",
        borderLeftWidth: 1,
    },
    signatureText: {
        fontSize: 8,
        marginTop: 3,
        paddingTop: 4,
        borderTop: "1px dotted #aaa",
    },
    emSignature: {
        fontSize: 8,
        marginTop: 2,
        textAlign: "center",
        alignItems: "center",
    },
    netPayableBox: {
        marginTop: 10,
        display: "flex",
        flexDirection: "row",
    },
    netPayable: {
        fontSize: 12,
        fontWeight: 700,
        paddingRight: 8,
    },
    tableTextTaka: {
        margin: "auto",
        color: "#222222",
        fontSize: "10px",
        fontWeight: "700",
        marginLeft: 50,
    },
    tableCelltakaleft: {
        marginTop: 3,
        marginBottom: 3,
        fontSize: 8,
        marginLeft: 95,
    },
    tableCelltakaright: {
        marginTop: 3,
        marginBottom: 3,
        fontSize: 8,
        marginLeft: 5,
    },
    tableTextleft: {
        marginLeft: 78,
        color: "#222222",
        fontSize: "10px",
        fontWeight: "700",
    },
    tableTextright: {
        marginLeft: 3,
        color: "#222222",
        fontSize: "10px",
        fontWeight: "700",
    },
});

function MyDocument({ managePaySlip }) {
    const date = new Date();
    const formattedDate = date.toLocaleDateString("en-US");

    const [manageCompany, setmanageCompany] = useState([]);

    useEffect(() => {
        fetchCompany();
    }, []);

    const fetchCompany = async () => {
        await axios
            .get(process.env.REACT_APP_API + "/company/user/1")
            .then(({ data }) => {
                setmanageCompany(data);
            });
    };

    const [manageSignatory, setmanageSignatory] = useState([]);

    useEffect(() => {
        fetchSignatory();
    }, []);

    const fetchSignatory = async () => {
        await axios
            .get(process.env.REACT_APP_API + "/signatory/APILimited/")
            .then(({ data }) => {
                setmanageSignatory(data);
            });
    };

    const [totalEarnings, setTotalEarnings] = useState(0);
    const [totalDeductions, setTotalDeductions] = useState(0);

    useEffect(() => {
        setTotalEarnings(0);
        setTotalDeductions(0);

        managePaySlip.forEach((item) => {
            item.earnings.forEach((earning) => {
                setTotalEarnings(
                    (totalEarnings) =>
                        parseInt(totalEarnings) + parseInt(earning.amount)
                );
            });

            item.deductions.forEach((deduction) => {
                setTotalDeductions(
                    (totalDeductions) =>
                        parseInt(totalDeductions) + parseInt(deduction.amount)
                );
            });
        });
    }, [managePaySlip]);

    // var today = new Date();

    // // Getting full month name (e.g. "June")
    // var month = today.toLocaleString("default", { month: "long" });
    // console.log(month);

    const current = new Date();
    current.setMonth(current.getMonth() - 1);
    const month = current.toLocaleString("default", { month: "long" });

    return (
        <Document>
            <Page style={styles.body}>
                <View style={styles.jiaur}>
                    {manageCompany.length > 0 &&
                        manageCompany.map((row, key) => (
                            <View style={styles.header}>
                                <Image style={styles.logo} src={logo} />
                                <Text style={styles.APItitle}>
                                    {row.company_name}
                                </Text>
                                <Text style={styles.addressPhone}>
                                    Address: {row.address}
                                </Text>
                                <Text style={styles.addressPhone}>
                                    Phone: {row.phone}, E-mail:
                                    {row.email}
                                </Text>
                            </View>
                        ))}

                    {managePaySlip.length > 0 &&
                        managePaySlip.map((row, key) => (
                            <View style={styles.employeeInfoBox}>
                                <View style={styles.employeeInfo}>
                                    <Text style={styles.salarySlip}>
                                        Salary Slip: {month}
                                    </Text>
                                    <Text style={styles.employee}>
                                        Employee: {row.employee_name}
                                    </Text>
                                    <Text style={styles.employee}>
                                        Designation: {row.designation}
                                    </Text>
                                    <Text style={styles.employee}>
                                        Address: {row.address}
                                    </Text>
                                    <Text style={styles.employee}>
                                        Mode Of Payment: Cash
                                    </Text>
                                </View>
                                <View style={styles.employeeInfo}>
                                    <Text style={styles.salarySlip}>
                                        EID-{row.E_ID}
                                    </Text>
                                    <Text style={styles.employee}>
                                        Phone: {row.phone}
                                    </Text>
                                    <Text style={styles.employee}>
                                        Date: {formattedDate}
                                    </Text>

                                    <Text style={styles.employee}>
                                        Slip: Office Copy
                                    </Text>
                                </View>
                            </View>
                        ))}

                    {managePaySlip.length > 0 &&
                        managePaySlip.map((row, key) => (
                            <View style={styles.tableBox} key={key}>
                                <View style={styles.table}>
                                    <View style={styles.tableHead}>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableText}>
                                                Earning
                                            </Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableText}>
                                                Amount in Taka
                                            </Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableText}>
                                                Deductions
                                            </Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableText}>
                                                Amount in Taka
                                            </Text>
                                        </View>
                                    </View>

                                    {row.earnings.map((item, key) => (
                                        <View style={styles.tableRow}>
                                            <View style={styles.tableCol}>
                                                <Text
                                                    style={
                                                        styles.tableCelltakaright
                                                    }
                                                >
                                                    {row.earnings[key].entity}
                                                </Text>
                                            </View>
                                            <View style={styles.tableCol}>
                                                <Text
                                                    style={
                                                        styles.tableCelltakaleft
                                                    }
                                                >
                                                    {row.earnings[key].amount}
                                                </Text>
                                            </View>
                                            <View style={styles.tableCol}>
                                                <Text
                                                    style={
                                                        styles.tableCelltakaright
                                                    }
                                                >
                                                    {row.deductions[key].entity}
                                                </Text>
                                            </View>
                                            <View style={styles.tableCol}>
                                                <Text
                                                    style={
                                                        styles.tableCelltakaleft
                                                    }
                                                >
                                                    {row.deductions[key].amount}
                                                </Text>
                                            </View>
                                        </View>
                                    ))}

                                    <View style={styles.totalTk}>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableTextright}>
                                                Total Earning:
                                            </Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableTextleft}>
                                                Tk {totalEarnings}
                                            </Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableTextright}>
                                                Total Deductions:
                                            </Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableTextleft}>
                                                Tk {totalDeductions}
                                            </Text>
                                        </View>
                                    </View>

                                    <View style={styles.netPayableBox}>
                                        <Text style={styles.netPayable}>
                                            Net Payable Salary:
                                        </Text>
                                        <Text style={styles.netPayable}>
                                            {totalEarnings - totalDeductions}/-
                                        </Text>
                                        <Text style={styles.netPayable}>
                                            In Words:{" "}
                                            {converter.toWords(
                                                totalEarnings - totalDeductions
                                            )}{" "}
                                            Taka Only.
                                        </Text>
                                    </View>
                                    <View style={styles.signature}>
                                        <View style={styles.emSignature}>
                                            <Text style={styles.employee}>
                                                {row.employee_name}
                                            </Text>
                                            <Text style={styles.signatureText}>
                                                Signature Of Employee
                                            </Text>
                                        </View>
                                        {manageSignatory.length > 0 &&
                                            manageSignatory.map((row, key) => (
                                                <View
                                                    style={styles.emSignature}
                                                >
                                                    <Text
                                                        style={styles.employee}
                                                    >
                                                        {row.Name}
                                                    </Text>
                                                    <Text
                                                        style={
                                                            styles.signatureText
                                                        }
                                                    >
                                                        Signature Of{" "}
                                                        {row.designation}
                                                    </Text>
                                                </View>
                                            ))}
                                    </View>
                                </View>
                            </View>
                        ))}
                </View>

                <View style={styles.jiaur}>
                    {manageCompany.length > 0 &&
                        manageCompany.map((row, key) => (
                            <View style={styles.header}>
                                <Image style={styles.logo} src={logo} />
                                <Text style={styles.APItitle}>
                                    {row.company_name}
                                </Text>
                                <Text style={styles.addressPhone}>
                                    Address: {row.address}
                                </Text>
                                <Text style={styles.addressPhone}>
                                    Phone: {row.phone}, E-mail:
                                    {row.email}
                                </Text>
                            </View>
                        ))}

                    {managePaySlip.length > 0 &&
                        managePaySlip.map((row, key) => (
                            <View style={styles.employeeInfoBox}>
                                <View style={styles.employeeInfo}>
                                    <Text style={styles.salarySlip}>
                                        Salary Slip: {month}
                                    </Text>
                                    <Text style={styles.employee}>
                                        Employee: {row.employee_name}
                                    </Text>
                                    <Text style={styles.employee}>
                                        Designation: {row.designation}
                                    </Text>
                                    <Text style={styles.employee}>
                                        Address: {row.address}
                                    </Text>
                                    <Text style={styles.employee}>
                                        Mode Of Payment: Cash
                                    </Text>
                                </View>
                                <View style={styles.employeeInfo}>
                                    <Text style={styles.salarySlip}>
                                        EID-{row.E_ID}
                                    </Text>

                                    <Text style={styles.employee}>
                                        Phone: {row.phone}
                                    </Text>
                                    <Text style={styles.employee}>
                                        Date: {formattedDate}
                                    </Text>
                                    <Text style={styles.employee}>
                                        Slip: Employee Copy
                                    </Text>
                                </View>
                            </View>
                        ))}

                    {managePaySlip.length > 0 &&
                        managePaySlip.map((row, key) => (
                            <View style={styles.tableBox} key={key}>
                                <View style={styles.table}>
                                    <View style={styles.tableHead}>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableText}>
                                                Earning
                                            </Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableText}>
                                                Amount in Taka
                                            </Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableText}>
                                                Deductions
                                            </Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableText}>
                                                Amount in Taka
                                            </Text>
                                        </View>
                                    </View>

                                    {row.earnings.map((item, key) => (
                                        <View style={styles.tableRow}>
                                            <View style={styles.tableCol}>
                                                <Text
                                                    style={
                                                        styles.tableCelltakaright
                                                    }
                                                >
                                                    {row.earnings[key].entity}
                                                </Text>
                                            </View>
                                            <View style={styles.tableCol}>
                                                <Text
                                                    style={
                                                        styles.tableCelltakaleft
                                                    }
                                                >
                                                    {row.earnings[key].amount}
                                                </Text>
                                            </View>
                                            <View style={styles.tableCol}>
                                                <Text
                                                    style={
                                                        styles.tableCelltakaright
                                                    }
                                                >
                                                    {row.deductions[key].entity}
                                                </Text>
                                            </View>
                                            <View style={styles.tableCol}>
                                                <Text
                                                    style={
                                                        styles.tableCelltakaleft
                                                    }
                                                >
                                                    {row.deductions[key].amount}
                                                </Text>
                                            </View>
                                        </View>
                                    ))}

                                    <View style={styles.totalTk}>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableTextright}>
                                                Total Earning:
                                            </Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableTextleft}>
                                                Tk {totalEarnings}
                                            </Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableTextright}>
                                                Total Deductions:
                                            </Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableTextleft}>
                                                Tk {totalDeductions}
                                            </Text>
                                        </View>
                                    </View>

                                    <View style={styles.netPayableBox}>
                                        <Text style={styles.netPayable}>
                                            Net Payable Salary:
                                        </Text>
                                        <Text style={styles.netPayable}>
                                            {totalEarnings - totalDeductions}/-
                                        </Text>
                                        <Text style={styles.netPayable}>
                                            In Words:{" "}
                                            {converter.toWords(
                                                totalEarnings - totalDeductions
                                            )}{" "}
                                            Taka Only.
                                        </Text>
                                    </View>
                                    <View style={styles.signature}>
                                        <View style={styles.emSignature}>
                                            <Text style={styles.employee}>
                                                {row.employee_name}
                                            </Text>
                                            <Text style={styles.signatureText}>
                                                Signature Of Employee
                                            </Text>
                                        </View>
                                        {manageSignatory.length > 0 &&
                                            manageSignatory.map((row, key) => (
                                                <View
                                                    style={styles.emSignature}
                                                >
                                                    <Text
                                                        style={styles.employee}
                                                    >
                                                        {row.Name}
                                                    </Text>
                                                    <Text
                                                        style={
                                                            styles.signatureText
                                                        }
                                                    >
                                                        Signature Of{" "}
                                                        {row.designation}
                                                    </Text>
                                                </View>
                                            ))}
                                    </View>
                                </View>
                            </View>
                        ))}
                </View>
            </Page>
        </Document>
    );
}
export default MyDocument;
