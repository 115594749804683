import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import MyDocument from "./MyDocument";
import { PDFDownloadLink } from "@react-pdf/renderer";
import ReactToPrint, { useReactToPrint } from "react-to-print";
var converter = require("number-to-words");
export default function PaySlip() {
  const navigate = useNavigate();
  const [validationError, setValidationError] = useState({});
  const date = new Date();
  const formattedDate = date.toLocaleDateString("en-US");

  const [manageCompany, setmanageCompany] = useState([]);

  useEffect(() => {
    fetchCompany();
  }, []);

  const fetchCompany = async () => {
    await axios
      .get(process.env.REACT_APP_API + "/company/user/1")
      .then(({ data }) => {
        setmanageCompany(data);
      });
  };

  const handlePrint = () => {
    window.print();
  };

  const { ID } = useParams();
  const [managePaySlip, setmanagePaySlip] = useState([]);

  useEffect(() => {
    fetchPaySlip();
  }, []);

  const fetchPaySlip = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_API}/final_salary_report/APILimited/${ID}`
      )
      .then(({ data }) => {
        setmanagePaySlip(data);
        localStorage.setItem("mydatapayslip", JSON.stringify(data));
      });
  };

  const [totalEarnings, setTotalEarnings] = useState(0);
  const [totalDeductions, setTotalDeductions] = useState(0);

  useEffect(() => {
    setTotalEarnings(0);
    setTotalDeductions(0);

    managePaySlip.forEach((item) => {
      item.earnings.forEach((earning) => {
        setTotalEarnings(
          (totalEarnings) => parseInt(totalEarnings) + parseInt(earning.amount)
        );
      });

      item.deductions.forEach((deduction) => {
        setTotalDeductions(
          (totalDeductions) =>
            parseInt(totalDeductions) + parseInt(deduction.amount)
        );
      });
    });
  }, [managePaySlip]);

  const [manageSignatory, setmanageSignatory] = useState([]);

  useEffect(() => {
    fetchSignatory();
  }, []);

  const fetchSignatory = async () => {
    await axios
      .get(process.env.REACT_APP_API + "/signatory/APILimited/")
      .then(({ data }) => {
        // console.log(data)
        const filteredData = data.filter(entry => entry.ID === 15 || entry.ID === 16);
        setmanageSignatory(filteredData);
      });
  };

  // var today = new Date();

  // // Getting full month name (e.g. "June")
  // var month = today.toLocaleString('default', { month: 'long' });
  // console.log(month);

  const current = new Date();
  current.setMonth(current.getMonth() - 1);
  const month = current.toLocaleString("default", { month: "long" });

  const ref = useRef();

  const formatMoneyAmount = (amount) => {
    const formatter = new Intl.NumberFormat('en-BD', {
      style: 'decimal',
      // minimumFractionDigits: 2,
      // maximumFractionDigits: 2,
    });

    return formatter.format(amount);
  }

  const handleReactPrint = useReactToPrint({
    content: () => ref.current,
  });

  return (
    <div>
      <div className="row">
        <div className="col-xl-12 col-md-12">
          {/* <ReactToPrint
            trigger={() => (
              <button
                className="btn btn btn-primary  aliginrightdata"
                type="button"
              >
                {" "}
                <i class="fa fa-print"></i> Print
              </button>
            )}
            content={() => ref.current}
          /> */}

            <button
              className="btn btn btn-primary  aliginrightdata"
              type="button"
              onClick={() => handleReactPrint()}
            >
              {" "}
              <i class="fa fa-print"></i> Print
            </button>

          <div ref={ref} className="card mb-4">
            {managePaySlip.length > 0 &&
              managePaySlip.map((row, key) => (
                <div className="card-body">
                  <div className="main-invoice">
                    {manageCompany.length > 0 &&
                      manageCompany.map((row, key) => (
                        <div className="invoice-heading text-center">
                          <img
                            className="mx-auto invoice-logo my-2"
                            src={row.logo}
                            width="100"
                            height="60"
                            alt=""
                          />
                          <h5>{row.company_name}</h5>

                          <address className="mb-3">
                            <div className="box-content">
                              <p className="mb-0">{row.address}</p>
                              <p className="mb-0">
                                Ph: {row.phone} M:
                                {row.mobile}
                              </p>
                              <p className="mb-0">Email: {row.email}</p>
                            </div>
                          </address>
                        </div>
                      ))}

                    <hr />

                    <div className="mb-0">
                      <div className="invoice-meta row mb-3">
                        <div className="col-md-6">
                          <div className="client-info">
                            <h4 className="title">Salary Slip: {month}</h4>
                            <div>
                              <span className="font-weight-bold spanWidth">
                                Employee:
                              </span>
                              {row.employee_name}
                            </div>

                            <div>
                              <span className="font-weight-bold spanWidth">
                                Designation:
                              </span>
                              {row.designation}
                            </div>
                            <div>
                              <span className="font-weight-bold spanWidth">
                                Address:
                              </span>

                              {row.address}
                            </div>
                            <div>
                              <span className="font-weight-bold spanWidth">
                                Phone:
                              </span>
                              {row.phone}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 text-right">
                          <div className="invoice-info" style={{width: "100%", textAlign: "right"}}>
                            <h4 className="title">EID-{row.E_ID}</h4>
                            <div>
                              <strong>Date: &nbsp; </strong> {formattedDate}
                            </div>
                            <div>
                              <strong>Slip: &nbsp;</strong>
                              Office Copy
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="table-responsive invoice-table">
                        <div class="container card mb-5" style={{minWidth: "100%"}}>
                          <div class="row g-0">
                            <div class="col-6 col-md-6">
                              <table class="table border-right">
                                <thead>
                                  <tr>
                                    <th scope="col">Earning</th>
                                    <th scope="col" class="text-end pe-4">
                                      Amount in Taka
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {row.earnings.map((item) => (
                                    <tr>
                                      <td>{item.entity}</td>
                                      <td class="pe-4 text-end">
                                        {formatMoneyAmount(item.amount)}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                            <div class="col-6 col-md-6">
                              <table class="table border-left">
                                <thead>
                                  <tr>
                                    <th scope="col">Deductions</th>
                                    <th scope="col" class="text-end pe-4">
                                      Amount in Taka
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {row.deductions.map((item) => (
                                    <tr>
                                      <td>{item.entity}</td>
                                      <td class="pe-4 text-end">
                                        {formatMoneyAmount(item.amount)}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div class="totalTk d-flex justify-content-between">
                            <h6 class="bg-light d-flex justify-content-between w-50 px-3 pb-3 pt-2 bg-secondary">
                              <b>Total Earning</b>
                              <b>BDT {formatMoneyAmount(totalEarnings)}</b>
                            </h6>

                            <h6 class="bg-light d-flex justify-content-between w-50 px-3 pb-3 pt-2 bg-secondary">
                              <b>Total Deductions</b>
                              <b>BDT {formatMoneyAmount(totalDeductions)}</b>
                            </h6>

                            <h6 class="bg-light d-flex justify-content-between w-50 px-3 pb-3 pt-2 bg-secondary">
                              <b>Net Payable Salary:</b>
                              <b>BDT {formatMoneyAmount(totalEarnings - totalDeductions)}</b>
                            </h6>
                          </div>

                          <div class="totalTk d-flex justify-content-between">
                            <p>
                              Net Payable Salary: BDT
                              {formatMoneyAmount(totalEarnings - totalDeductions)}; In Words:{" "}
                              <span className="text-capitalize">
                              {converter.toWords(
                                totalEarnings - totalDeductions
                              )}
                              </span>
                              Taka Only.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="invoice-meta row align-items-end justify-content-between">
                    <div className="col-md-4">
                      <div className="invoice-info text-center">
                        <p className="mb-1">{row.employee_name}</p>
                        <h6 className="title designationsignater">
                          Signature Of Employee
                        </h6>
                      </div>
                    </div>

                    {manageSignatory.length > 0 &&
                      manageSignatory.map((row, key) => (
                        <div className="col-md-4" key={key}>
                          <div className="invoice-info text-center">
                            <p className="mb-1">{row.Name}</p>
                            <h6 className="title designationsignater">
                              Signature Of &nbsp;
                              {row.designation}
                            </h6>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              ))}

            {managePaySlip.length > 0 &&
              managePaySlip.map((row, key) => (
                <div className="card-body mt-10">
                  <div className="main-invoice">
                    {manageCompany.length > 0 &&
                      manageCompany.map((row, key) => (
                        <div className="invoice-heading text-center">
                          <img
                            className="mx-auto invoice-logo my-2"
                            src={row.logo}
                            width="100"
                            height="60"
                            alt=""
                          />
                          <h5>{row.company_name}</h5>

                          <address className="mb-3">
                            <div className="box-content">
                              <p className="mb-0">{row.address}</p>
                              <p className="mb-0">
                                Ph: {row.phone} M:
                                {row.mobile}
                              </p>
                              <p className="mb-0">Email: {row.email}</p>
                            </div>
                          </address>
                        </div>
                      ))}

                    <hr />

                    <div className="mb-0">
                      <div className="invoice-meta row mb-3">
                        <div className="col-md-6">
                          <div className="client-info">
                            <h4 className="title">Salary Slip: {month}</h4>
                            <div>
                              <span className="font-weight-bold spanWidth">
                                Employee:
                              </span>
                              {row.employee_name}
                            </div>

                            <div>
                              <span className="font-weight-bold spanWidth">
                                Designation:
                              </span>
                              {row.designation}
                            </div>
                            <div>
                              <span className="font-weight-bold spanWidth">
                                Address:
                              </span>

                              {row.address}
                            </div>
                            <div>
                              <span className="font-weight-bold spanWidth">
                                Phone:
                              </span>
                              {row.phone}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-12 text-right">
                          <div className="invoice-info" style={{width: "100%", textAlign: "right"}}>
                            <h4 className="title">EID-{row.E_ID}</h4>
                            <div>
                              <strong>Date: &nbsp; </strong> {formattedDate}
                            </div>
                            <div>
                              <strong>Slip: &nbsp;</strong>
                              Employee Copy
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="table-responsive invoice-table">
                        <div class="container card mb-5" style={{minWidth: "100%"}}>
                          <div class="row g-0">
                            <div class="col-6 col-md-6">
                              <table class="table border-right">
                                <thead>
                                  <tr>
                                    <th scope="col">Earning</th>
                                    <th scope="col" class="text-end pe-4">
                                      Amount in Taka
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {row.earnings.map((item) => (
                                    <tr>
                                      <td>{item.entity}</td>
                                      <td class="pe-4 text-end">
                                        {formatMoneyAmount(item.amount)}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                            <div class="col-6 col-md-6">
                              <table class="table border-left">
                                <thead>
                                  <tr>
                                    <th scope="col">Deductions</th>
                                    <th scope="col" class="text-end pe-4">
                                      Amount in Taka
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {row.deductions.map((item) => (
                                    <tr>
                                      <td>{item.entity}</td>
                                      <td class="pe-4 text-end">
                                        {formatMoneyAmount(item.amount)}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div class="totalTk d-flex justify-content-between">
                            <h6 class="bg-light d-flex justify-content-between w-50 px-3 pb-3 pt-2 bg-secondary">
                              <b>Total Earning</b>
                              <b>{formatMoneyAmount(totalEarnings)}</b>
                            </h6>

                            <h6 class="bg-light d-flex justify-content-between w-50 px-3 pb-3 pt-2 bg-secondary">
                              <b>Total Deductions</b>
                              <b>BDT {formatMoneyAmount(totalDeductions)}</b>
                            </h6>

                            <h6 class="bg-light d-flex justify-content-between w-50 px-3 pb-3 pt-2 bg-secondary">
                              <b>Net Payable Salary:</b>
                              <b>{formatMoneyAmount(totalEarnings - totalDeductions)}</b>
                            </h6>
                          </div>
                          <div class="totalTk d-flex justify-content-between">
                            <p>
                              Net Payable Salary:{" "}
                              {formatMoneyAmount(totalEarnings - totalDeductions)}; In Words:{" "}
                              <span className="text-capitalize">
                              {converter.toWords(
                                totalEarnings - totalDeductions
                              )}
                              </span>
                              {" "}Taka Only.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="invoice-meta row align-items-end justify-content-between">
                    <div className="col-md-4">
                      <div className="invoice-info text-center">
                        <p className="mb-1">{row.employee_name}</p>
                        <h6 className="title designationsignater">
                          Signature Of Employee
                        </h6>
                      </div>
                    </div>

                    {manageSignatory.length > 0 &&
                      manageSignatory.map((row, key) => (
                        <div className="col-md-4" key={key}>
                          <div className="invoice-info text-center">
                            <p className="mb-1">{row.Name}</p>
                            <h6 className="title designationsignater">
                              Signature Of &nbsp;
                              {row.designation}
                            </h6>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
