import React from 'react';
import Table from 'react-bootstrap/Table';
export default function SalarayArchive() {
    return(
        <div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                    <th>SL</th>
                    <th>Employee ID</th>
                    <th>First Name</th>
                    <th>InTime</th>
                    <th>OutTime</th>
                    <th>Date</th>
                    </tr>
                </thead>
                    
                <tbody>
                    <tr>
                    <td>1</td>
                    <td>Md.Mizanur Rahaman</td>
                    <td>API2007001</td>
                    <td>11:28:32 AM</td>
                    <td>00:00:00 PM</td>
                    <td>2022-1-5 </td>
                    </tr>
                    <tr>
                    <td>1</td>
                    <td>Md.Mizanur Rahaman</td>
                    <td>API2007001</td>
                    <td>11:28:32 AM</td>
                    <td>00:00:00 PM</td>
                    <td>2022-1-5 </td>
                    </tr>
                    <tr>
                    <td>1</td>
                    <td>Md.Mizanur Rahaman</td>
                    <td>API2007001</td>
                    <td>11:28:32 AM</td>
                    <td>00:00:00 PM</td>
                    <td>2022-1-5 </td>
                    </tr>
                    <tr>
                    <td>1</td>
                    <td>Md.Mizanur Rahaman</td>
                    <td>API2007001</td>
                    <td>11:28:32 AM</td>
                    <td>00:00:00 PM</td>
                    <td>2022-1-5 </td>
                    </tr>
                    <tr>
                    <td>1</td>
                    <td>Md.Mizanur Rahaman</td>
                    <td>API2007001</td>
                    <td>11:28:32 AM</td>
                    <td>00:00:00 PM</td>
                    <td>2022-1-5 </td>
                    </tr>
                    <tr>
                    <td>1</td>
                    <td>Md.Mizanur Rahaman</td>
                    <td>API2007001</td>
                    <td>11:28:32 AM</td>
                    <td>00:00:00 PM</td>
                    <td>2022-1-5 </td>
                    </tr>
                    <tr>
                    <td>1</td>
                    <td>Md.Mizanur Rahaman</td>
                    <td>API2007001</td>
                    <td>11:28:32 AM</td>
                    <td>00:00:00 PM</td>
                    <td>2022-1-5 </td>
                    </tr>
                    <tr>
                    <td>1</td>
                    <td>Md.Mizanur Rahaman</td>
                    <td>API2007001</td>
                    <td>11:28:32 AM</td>
                    <td>00:00:00 PM</td>
                    <td>2022-1-5 </td>
                    </tr>
                    <tr>
                    <td>1</td>
                    <td>Md.Mizanur Rahaman</td>
                    <td>API2007001</td>
                    <td>11:28:32 AM</td>
                    <td>00:00:00 PM</td>
                    <td>2022-1-5 </td>
                    </tr>
                    <tr>
                    <td>1</td>
                    <td>Md.Mizanur Rahaman</td>
                    <td>API2007001</td>
                    <td>11:28:32 AM</td>
                    <td>00:00:00 PM</td>
                    <td>2022-1-5 </td>
                    </tr>
                    <tr>
                    <td>1</td>
                    <td>Md.Mizanur Rahaman</td>
                    <td>API2007001</td>
                    <td>11:28:32 AM</td>
                    <td>00:00:00 PM</td>
                    <td>2022-1-5 </td>
                    </tr>
                </tbody>
                </Table>
                </div>
        );
}