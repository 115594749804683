import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

export default function CmEarning() {
    const navigate = useNavigate();
    const [validationError, setValidationError] = useState({});
    const [manageDesignations, setManageEmployees] = useState([]);

    const [manageemployee, setManageEmployee] = useState("");

    useEffect(() => {
        fetchManageEmployee();
    }, []);

    const fetchManageEmployee = async () => {
        await axios
            .get(process.env.REACT_APP_API + "/employee/APILimited")
            .then(({ data }) => {
                setManageEmployee(data);
            });
    };

    const [inputarr, setInputarr] = useState([]);
    const [inputdata, SetInputdata] = useState({
        E_ID: "",
        Type: "",
        name: "",
        Entity: "",
        Amount: "",
        Date: "",
    });

    const [totalEarnings, setTotalEarnings] = useState(0);
    const [totalDeductions, setTotalDeductions] = useState(0);

    useEffect(() => {
        setTotalEarnings(0);
        setTotalDeductions(0);

        inputarr.forEach((item) => {
            if (item.Type === "1") {
                setTotalEarnings(
                    (totalEarnings) =>
                        parseInt(totalEarnings) + parseInt(item.Amount)
                );
            }
            if (item.Type === "2") {
                setTotalDeductions(
                    (totalDeductions) =>
                        parseInt(totalDeductions) + parseInt(item.Amount)
                );
            }
        });
    }, [inputarr]);

    const [selectedValue, setSelectedValue] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    function changehandle(e) {
        setSelectedValue(e.target.value);
        if (e.target.value === "Select an option") {
            setErrorMessage("Please select a valid option");
        } else {
            setErrorMessage("");
        }

        SetInputdata({
            ...inputdata,
            [e.target.name]: e.target.value,
        });
    }

    function changhandle() {
        let { E_ID, name, Type, Entity, Amount, Date } = inputdata;

        if (!E_ID || !Type) return false;

        setInputarr([
            ...inputarr,
            {
                E_ID,
                name,
                Type,
                Entity,
                Amount,
                Date,
            },
        ]);
        //console.log(inputdata, "input data what we Enter");
        SetInputdata({
            E_ID: "",
            name: "",
            Type: "",
            Entity: "",
            Amount: "",
            Date: "",
        });
    }

    //console.log(inputarr);

    const handleButtonClick = () => {
        inputarr.forEach((item) => {
            axios
                .post(
                  process.env.REACT_APP_API + "/monthly_earning/APILimited",
                    item
                )
                .then((res) => {
                    console.log(res.data);
                    Swal.fire({
                        icon: "success",
                        timer: 1500,
                        text: res.message,
                    });
                    navigate("/cmearning");
                })
                .catch((err) => {
                    console.error(err);
                    if (err.status === 422) {
                        setValidationError(err.data.errors);
                    } else {
                        Swal.fire({
                            text: err.data.message,
                            icon: "error",
                        });
                    }
                });
        });
    };

    const [entity, setManageEntity] = useState("");

    useEffect(() => {
        fetchManageEntity();
    }, []);

    const fetchManageEntity = async () => {
        await axios
            .get(
              process.env.REACT_APP_API + "/fixed_earning_entity/APILimited"
            )
            .then(({ data }) => {
                setManageEntity(data);
            });
    };

    const handleReset = () => {
        setSelectedValue('');
      };

    console.log(inputarr);



    return (
        <div>
            <div className="container card mb-2 pt-3">
                <h4 className="card-title">Create CM Earning</h4>
                <hr />
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="Designation">
                        <Form.Label>Employee ID </Form.Label>


                        <Form.Select
                            aria-label="Default select example"
                            id="E_ID"
                            autoComplete="off"
                            name="E_ID"
                            value={selectedValue}
                            onChange={changehandle}
                            placeholder="Enter E_ID"
                        >
                            <option>Select an option</option>
                            {manageemployee.length > 0 &&
                                manageemployee.map((row) => (
                                    <option value={row.Employee_ID} key={row.Employee_ID}>
                                        {row.Employee_ID}-{row.employee_name}
                                    </option>
                                ))}
                        </Form.Select>

                        {errorMessage && (
                            <div style={{ color: "red" }}>{errorMessage}</div>
                        )}
                    </Form.Group>

                    <Form.Group as={Col} controlId="Entityd">
                        <Form.Label>Type</Form.Label>
                        <Form.Select
                            aria-label="Default select example"
                            id="Type"
                            value={selectedValue}
                            autoComplete="off"
                            name="Type"
                            required
                            // value={inputdata.Type}
                            onChange={changehandle}
                            placeholder="Enter Type"
                        >
                            <option>Select an option</option>
                            <option value="1">Earning</option>
                            <option value="2">Deduction</option>
                        </Form.Select>
                        {errorMessage && (
                            <div style={{ color: "red" }}>{errorMessage}</div>
                        )}
                    </Form.Group>

                    <Form.Group as={Col} controlId="Entity">
                                <Form.Label>Entity</Form.Label>
                                <Form.Select
                                    defaultValue="Choose..."
                                    id="Entity"
                                    required

                                    name="Entity"
                                    value={selectedValue}
                                    onChange={changehandle}
                                    placeholder="Entity"
                                >
                                    <option>Select an option</option>

                                    {entity.length > 0 &&
                                        entity.map((row) => (
                                            <option
                                                value={row.entity}
                                                key={row.entity}
                                            >
                                                {row.entity}
                                            </option>
                                        ))}
                                </Form.Select>
                            </Form.Group>
                </Row>

                <Row className="mb-3">
                    <Form.Group as={Col} controlId="Amount">
                        <Form.Label>Amount</Form.Label>
                        <Form.Control
                            type="text"
                            required
                            name="Amount"
                            value={inputdata.Amount}
                            onChange={changehandle}
                            placeholder="Amount"
                        />
                    </Form.Group>
                    {/* <Form.Group as={Col} controlId="date">
                        <Form.Label>Date</Form.Label>
                        <Form.Control
                            type="Date"
                            autoComplete="off"
                            name="Date"
                            value={inputdata.Date}
                            onChange={changehandle}
                            placeholder="Date"
                        />
                    </Form.Group> */}

                </Row>

                <Form.Group as={Row} className="mb-3">
                    <Col sm={{ span: 0, offset: 20 }}>
                        <Button
                            variant="secondary"
                            onClick={changhandle}
                            type="submit"
                        >
                              <i class="fa fa-save"></i> Add
                        </Button>
                        <Button className="ms-3"
                         variant="secondary"
                         onClick={handleReset}
                         type="submit">
                        <i class="fa fa-times"></i> Reset
                        </Button>
                    </Col>

                </Form.Group>
            </div>

            <div className="table-responsive invoice-table">
                <div className="container card mb-5">
                    <div class="row g-0">
                        <div class="col-6 col-md-6">
                            <table class="table border-right">
                                <thead>
                                    <tr>
                                        <th scope="col">Earning</th>
                                        <th
                                            scope="col"
                                            className="text-end pe-4"
                                        >
                                            Amount in Taka
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {inputarr.map((item, index) => {
                                        if (item.Type === "1") {
                                            return (
                                                <tr key={index}>
                                                    <td>{item.Entity}</td>
                                                    <td className="pe-4 text-end">
                                                        {item.Amount}
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div class="col-6 col-md-6">
                            <table class="table border-left">
                                <thead>
                                    <tr>
                                        <th scope="col">Deduction</th>
                                        <th
                                            scope="col"
                                            className="text-end pe-4"
                                        >
                                            Amount in Taka
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {inputarr.map((item, index) => {
                                        if (item.Type === "2") {
                                            return (
                                                <tr key={index}>
                                                    <td>{item.Entity}</td>
                                                    <td className="pe-4 text-end">
                                                        {item.Amount}
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="totalTk d-flex justify-content-between">
                        <h6 className="bg-light d-flex justify-content-between w-50 px-3 pb-3 pt-2 bg-secondary">
                            <b>Total Earning</b>
                            <b>৳ {totalEarnings}</b>
                        </h6>
                        <h6 className="bg-light d-flex justify-content-between w-50 px-3 pb-3 pt-2 bg-secondary">
                            <b>Total Deductions</b>
                            <b>৳ {totalDeductions}</b>
                        </h6>
                    </div>

                    <Form.Group
                        as={Row}
                        className="mb-3 offset-sm-20 text-end "
                    >
                        <Col sm={{ span: 0, offset: 20 }}>
                            <Button
                                onClick={handleButtonClick}
                                variant="secondary"
                                type="submit"
                            >
                                <i class="fa fa-save"></i> Submit
                            </Button>
                        </Col>
                    </Form.Group>
                </div>
            </div>
        </div>
    );
}
